import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Nav, Navbar } from "react-bootstrap";

import Menu from "../assets/images/icons/toggle.svg";
import WhatsappIconNew from "../assets/images/social/whatsapp.png";
import TelegramIconNew from "../assets/images/social/telegram.png";
import GamesSearchModal from "../views/components/GamesSearchModal";

import ChatIcon from "../assets/images/icons/comment.webp";
import LanguageIcon from "../assets/images/icons/web.webp";
import logo from "../assets/images/logo.webp";

import { APP_CONST } from "../config/const";
import ModalWrapper from "./ModalWrapper";

const Header = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const htmlElement = document.documentElement;
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  const handleToggleClick = () => {
    const realTimeValue = !showLeftbarToggle;
    if (realTimeValue) {
      // htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbat-opened");
    } else {
      // htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
    setShowLeftbarToggle(realTimeValue);
  };

  const handleModalShow = (modalName) => {
    // setShowAuthModals((p) => ({ ...p, [modalName]: true }));
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  return (
    <header
      className={
        scroll
          ? "main-header scrolled header beforeheader"
          : "main-header header beforeheader"
      }
    >
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="leftbar_toggle d-md-block d-none">
                  <Button variant="primary" onClick={() => handleToggleClick()}>
                    <img src={Menu} />
                  </Button>
                </div>
                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                    {/* <img src={logo} alt="Logo" /> */}
                  </a>
                </div>
              </div>

              <div className="headerRight">
                <ul className="social d-none d-sm-flex">
                  <li>
                    <GamesSearchModal />
                  </li>
                </ul>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                  className="button-primary btn_signin"
                >
                  Sign in
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                  }}
                  className="button-primary btn_signup btn-secondary mx-2"
                >
                  Sign Up
                </Button>

                {/* <ul className="chatnlanguage">
                  <li>
                    <a href="javascript:void(0)">
                      <img src={ChatIcon} />
                    </a>
                  </li>
                  {/* <li>
                    <a href="javascript:void(0)">
                      <img src={LanguageIcon} />
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          setShowAuthModals={setShowAuthModals}
        />
      )}
    </header>
  );
};

export default Header;
