import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Img1 from "../assets/images/icons/footer/casino-sigma-europe.webp";
import Img2 from "../assets/images/icons/footer/crypto-casino-affpapa.webp";
import Img3 from "../assets/images/icons/footer/mobile-sigma-africa.webp";
import Img4 from "../assets/images/icons/footer/platform-sigma-asia.webp";

import FootLogo1 from "../assets/images/icons/footer/sigma.webp";
import FootLogo2 from "../assets/images/icons/footer/responsible-gambling.webp";
import FootLogo3 from "../assets/images/icons/footer/gamcare.webp";
import FootLogo4 from "../assets/images/icons/footer/betblocker.webp";
import FootLogo5 from "../assets/images/icons/footer/plus.webp";
import FootLogo6 from "../assets/images/icons/footer/c9.webp";
import FootLogo7 from "../assets/images/icons/footer/leicester.png";
import FootLogo8 from "../assets/images/icons/footer/jason_derulo.webp";

import WhatsappIcon1 from "../assets/images/whatsapp.png";
import TelegramIcon from "../assets/images/Telegram.svg";
import InstaIcon from "../assets/images/Instagram.svg";
import Facebook from "../assets/images/facebook-512.svg";
import Calling from "../assets/images/phone-call.png";

import gcb from "../assets/images/icons/footer/gcb.webp";

import Menu from "../assets/images/icons/menu.webp";
import Explore from "../assets/images/icons/chat.webp";
import CasinoIcon from "../assets/images/icons/card-games.webp";
import SportsIcon from "../assets/images/icons/Sports.webp";
import Chat from "../assets/images/icons/searchfoot.webp";
import { CasinoProviders } from "../lib/data";

import UpDown from "../assets/images/icons/up-arrow.png";
import { Dropdown } from "react-bootstrap";

import Facebook1 from "../assets/images/facebook-512.svg";
import WhatsappIcon12 from "../assets/images/Whatsapp.svg";
import Calling1 from "../assets/images/phone-call.png";
import TelegramIcon1 from "../assets/images/Telegram.svg";
import InstaIcon1 from "../assets/images/Instagram.svg";
import GamesSearchModal from "../views/components/GamesSearchModal";

const BeforeLoginFooter = ({ scrollableContainerRef }) => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [activePage, setActivePage] = useState("");
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const htmlElement = document.documentElement;
  const handleToggleClick = () => {
    const realTimeValue = !showLeftbarToggle;
    if (realTimeValue) {
      htmlElement.style.overflow = "auto";
      document.body.classList.add("leftbat-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
    setShowLeftbarToggle(realTimeValue);
  };

  const scrollToTop = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1200) {
      if (scrollableContainerRef.current) {
        scrollableContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <footer className="footer">
      <div className="FooterCountrySection">
        <ul>
          <li>
            <img src={Img1} alt="Img1" />
          </li>
          <li>
            <img src={Img2} alt="Img1" />
          </li>
          <li>
            <img src={Img3} alt="Img1" />
          </li>
          <li>
            <img src={Img4} alt="Img1" />
          </li>
          <li>
            <img src={Img1} alt="Img1" />
          </li>
          <li>
            <img src={Img2} alt="Img1" />
          </li>
          <li>
            <img src={Img3} alt="Img1" />
          </li>
          <li>
            <img src={Img4} alt="Img1" />
          </li>
        </ul>
      </div>

      <div className="FooterLogo">
        <ul>
          <li>
            <img src={FootLogo1} alt="Footlogo1" />
          </li>
          <li>
            <img src={FootLogo2} alt="Footlogo1" />
          </li>
          <li>
            <img src={FootLogo3} alt="Footlogo1" />
          </li>
          <li>
            <img src={FootLogo4} alt="Footlogo1" />
          </li>
          <li>
            <img src={FootLogo5} alt="Footlogo1" />
          </li>
          <li>
            <img src={FootLogo6} alt="Footlogo1" />
          </li>
          <li>
            <img src={FootLogo7} alt="Footlogo1" />
          </li>
          <li>
            <img src={FootLogo8} alt="Footlogo1" />
          </li>
        </ul>
      </div>

      <div className="FooterLinksSection">
        <div className="footerLinks">
          <h2>Casino</h2>
          <ul>
            <li>
              <a href="/slots">Slots</a>
            </li>
            <li>
              <a href="/ezugi">Live Casino</a>
            </li>
            <li>
              <a href="/ezugi">Blackjack</a>
            </li>
            <li>
              <a href="/ezugi">Roulette</a>
            </li>
            <li>
              <a href="/ezugi">Baccarat</a>
            </li>
          </ul>
        </div>
        <div className="footerLinks">
          <h2>Sports</h2>
          <ul>
            <li>
              <a href="#">Exchange</a>
            </li>
            <li>
              <a href="#">Sportsbook</a>
            </li>
          </ul>
        </div>
        <div className="footerLinks">
          <h2>Promo</h2>
          <ul>
            <li>
              <a href="/affiliates">Affiliates</a>
            </li>
            <li>
              <a href="/promotion">Promotions</a>
            </li>

            <li>
              <a href="/referral">Refer a Friend</a>
            </li>
          </ul>
        </div>
        <div className="footerLinks">
          <h2>Support/Legal</h2>
          <ul>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicy">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegame">Responsible Gaming</a>
            </li>
          </ul>
        </div>
        <div className="footerLinks">
          <h2>About Us</h2>
          <ul>
            <li>
              <a href="/fairplay">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrule">Games Rules</a>
            </li>
            <li>
              <a href="/terms">Terms and Conditions</a>
            </li>
          </ul>
        </div>
        <div className="footerLinks">
          <h2>Join Our Community</h2>
          <ul>
            <li>
              <a href={`tel://${appDetails?.PHONE}`}>
                <img src={Calling} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={WhatsappIcon1} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={TelegramIcon} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={appDetails?.INSTAGRAM != "" ? appDetails?.INSTAGRAM : "#"}
                target={appDetails?.INSTAGRAM != "" ? "_blank" : ""}
              >
                <img src={InstaIcon} alt="Instagram Icon" />
              </a>
            </li>

            <li>
              <a
                href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src={Facebook} alt="Email Icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="FooterLogoContent">
        <div className="FooterleftPart">
          <a href="/">
            <img src={appDetails?.LOGO_URL} alt="Logo" />
          </a>
          <p>
            A multi-award winning crypto casino. With a player-centric approach,
            B11 is able to satisfy millions of gamblers across the globe. B11
            has its priority set on its community, ensuring an everlasting and
            endlessly entertaining gambling experience.
          </p>
          <p>
            Your use of and access to B11 signifies that you fully understand
            and agree to be legally bound by the contents of our Terms of
            Service and Responsible Gaming Policy
          </p>
          <p>
            Crypto trading is not gambling by definition, therefore it is not
            covered by our gaming license.
          </p>
        </div>
      </div>

      <div className="copyright">
        <p>Copyright ©2024 Fenice Tech Ltd. ALL RIGHTS RESERVED.</p>
      </div>

      <div className="scrolltoTop">
        <button type="btn" onClick={scrollToTop} className="d-lg-flex d-none">
          <img src={UpDown} />
        </button>
      </div>

      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
        <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
          <li>
            <a
              className={activePage === "sportsbook" ? "active" : ""}
              onClick={() => handleToggleClick()}
              href="#"
            >
              <img src={Menu} alt="sports" />
              <span>Menu</span>
            </a>
          </li>
          <li>
            <a href={CasinoProviders["/casino"]?.href}>
              {/* <img src={Chat} alt="aviator" /> */}
              <GamesSearchModal />
              <span>Explore</span>
            </a>
          </li>
          <li>
            <a className={activePage === "home" ? "active" : ""} href="/home">
              <img src={CasinoIcon} alt="home" />
              <span>Casino</span>
            </a>
          </li>
          <li>
            <a href={CasinoProviders["/spmsports/live"]?.href}>
              <img src={SportsIcon} alt="ezugi" />
              <span>Inplay</span>
            </a>
          </li>
          <Dropdown className="FooterDropdown">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <img src={Explore} alt="evolution" />
              <span>Support</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <ul>
                <li>
                  <a href={`tel://${appDetails?.PHONE}`}>
                    <img src={Calling1} alt="Whatsapp Icon" />
                    <span>Call</span>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={WhatsappIcon12} alt="Whatsapp Icon" />
                    <span>Whatsapp</span>
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={TelegramIcon1} alt="Telegram Icon" />
                    <span>Telegram</span>
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.INSTAGRAM != "" ? appDetails?.INSTAGRAM : "#"
                    }
                    target={appDetails?.INSTAGRAM != "" ? "_blank" : ""}
                  >
                    <img src={InstaIcon1} alt="Instagram Icon" />
                    <span>Instagram</span>
                  </a>
                </li>

                <li>
                  <a
                    href={
                      appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src={Facebook1} alt="Email Icon" />
                    <span>Facebook</span>
                  </a>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
      </div>
    </footer>
  );
};

export default BeforeLoginFooter;
