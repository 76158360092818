import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Slider from "react-slick";

const MainSlider = ({ banners, afterLoginBanner }) => {
  const { isAuth } = useSelector((state) => state.auth);
  const isDesktop = window?.screen?.width > 767 ? true : false;

  const slidesToShow = isAuth && isDesktop ? 3 : 1;
  const settings = {
    dots: true,
    // fade: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Slider {...settings}>
        {!isAuth
          ? banners &&
            banners.length &&
            banners.map((b, index) => {
              return (
                <div className="position-relative" key={index}>
                  <img
                    src={isDesktop ? b?.desktopBanner : b?.mobileBanner}
                    alt="First Slide"
                  />
                </div>
              );
            })
          : afterLoginBanner &&
            afterLoginBanner.length &&
            afterLoginBanner.map((b, index) => {
              return (
                <div className="position-relative" key={index}>
                  <img src={b?.afterLoginBanner} alt="First Slide" />
                </div>
              );
            })}
      </Slider>
    </div>
  );
};

export default MainSlider;
