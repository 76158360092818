import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { mobileVerification } from "../../../redux/auth/actions";
import { Toaster } from "../../../utils/toaster";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_CONST } from "../../../config/const";
import LoginBanner from "../../../assets/images/banner/login.webp";
import LoginBannerMob from "../../../assets/images/banner/loginmob.webp";

const MobileNumberVerifyModal = ({ show, handleClose, handleModalShow }) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [otp, setOtpValue] = useState("");
  const [mobileDetails, setMobileDetails] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getMobileDetails();
  }, []);
  const getMobileDetails = () => {
    const details =
      localStorage.getItem("mobile_details") &&
      JSON.parse(localStorage.getItem("mobile_details"));
    if (details) {
      setMobileDetails(details);
    }
  };

  const handleSubmit = () => {
    setIsVerifying(true);

    dispatch(mobileVerification({ ...mobileDetails, otp }))
      .then((res) => {
        setIsVerifying(false);
        handleClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER, "auto");
        handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
        // navigate("/register", {
        //   state: {
        //     referalCode: location?.state?.referalCode,
        //     agentCode: location?.state?.agentCode,
        //   },
        // });
      })
      .catch((err) => {
        setIsVerifying(false);
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
      });
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <div className="modalSection">
        <div className="LoginBanner">
          <img src={LoginBanner} alt="" className="d-md-block d-none" />
          <img src={LoginBannerMob} alt="" className="d-md-none d-block" />
        </div>
        <div className="LoginRightPart">
          <Modal.Header
            closeButton
            closeVariant="white"
            onClick={() => handleClose()}
          >
            <Modal.Title> Sign In</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-section">
              <div className="Login-rightPart">
                {/* <Modal.Title>Verify Number</Modal.Title> */}
                <div className="form-steps">
                  <h2 className="h2 " style={{ marginBottom: "30px" }}>
                    Verify!
                  </h2>
                  <h4 style={{ color: "#EFEFEF", marginBottom: "40px" }}>
                    Please enter OTP you received on your mobile number and
                    verify.
                  </h4>
                  <Form>
                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter OTP*"
                        onChange={(e) => setOtpValue(e.target.value)}
                      />
                      <span className="icon">{/* <EyeIcon /> */}</span>
                    </Form.Group>
                  </Form>

                  <Button
                    variant=""
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={isVerifying}
                    style={{ marginTop: "30px" }}
                  >
                    {!isVerifying ? "VERIFY" : "SUBMITTING"}
                  </Button>
                  <div className="sign-up mt-4">
                    Already have an account?
                    <br />
                    <a href="/sign-in">Sign In</a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default MobileNumberVerifyModal;
