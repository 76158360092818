import React, { useState, useEffect } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { casinoTabs } from "../../lib/data/casinoTabs.data";
import { casinoProvidersGamesdata } from "../../lib/data/casinoProvidersGamesdata";
import { SlotGames } from "../../lib/data/SlotGames.data";
import { auraGames } from "../../lib/data/auraGames.data";

import SearchIcon from "../../assets/images/icons/search.webp";
import { casinoSection } from "../../lib/data/casinoSection.data";

const SEARCH_TYPES = {
  SLOTS: "slots",
  AURA: "aura",
  HOME: "home",
};

const GamesSearchModal = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState(false);
  const isLandingPage = window.location.pathname === "/";
  const totalGames = {
    ...casinoTabs?.popular,
    ...casinoTabs?.aura,
    ...casinoTabs?.slotsgames,
    ...casinoTabs?.exclusive,
    ...casinoTabs?.auracardgames,
    ...casinoTabs?.spribe,
    ...casinoSection?.popularGames,
    ...casinoSection?.roulettetab,
    ...casinoSection?.AndarBahar,
    ...casinoSection?.blackjacktab,
    ...casinoSection?.baccarattab,
    ...casinoSection?.livecasino,
    ...casinoSection?.tablegame,
    ...casinoSection?.dragontiger,
  };

  useEffect(() => {
    return () => {
      setFilteredData(null);
    };
  }, []);

  const handleClose = () => {
    setFilteredData(null);
    setShow(false);
  };

  const filterPageSpecificData = (
    dataToFilter,
    searchValue,
    searchType = "pageSpecific"
  ) => {
    let localFilter = [];
    let slotsCategory = ["netent", "redTiger"];

    Object.values(dataToFilter).map((slot) => {
      if (
        (slot?.key && slotsCategory.includes(slot?.key)) ||
        window.location.pathname.toLowerCase().indexOf(SEARCH_TYPES.AURA) >
          -1 ||
        searchType === "all"
      ) {
        Object.values(slot).map((item) => {
          if (typeof item !== "string") {
            Object.values(item).map((f) => {
              if (
                f?.name?.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1
              ) {
                localFilter.push(f);
              }
            });
          }
        });
      }
    });
    return localFilter;
  };

  const handleSearch = (value) => {
    if (value) {
      setSearchText(true);
      let filteredValue = [];

      Object.values(totalGames).map((item) => {
        if (item?.name?.toLowerCase().indexOf(value?.toLowerCase()) > -1) {
          filteredValue.push(item);
        }
      });

      setFilteredData(filteredValue);
    } else {
      setSearchText(false);
    }
  };

  return (
    <>
      <div className="search-box">
        <div className="searchGames" onClick={handleShow}>
          <img src={SearchIcon} />
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="search-modal">
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Search Games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3 search-icon">
            <Form.Control
              placeholder="Search your favourite games here..."
              aria-label="Text input with dropdown button"
              type="search"
              onChange={(e) => handleSearch(e.target.value)}
              autoFocus
            />
          </InputGroup>

          <ul className="game-list">
            {filteredData &&
              filteredData.map((item, idx) => (
                <li key={idx}>
                  <a href={item?.href || item.redirectUrl}>
                    <img src={item.imgUrl} />
                    <span>{item.name}</span>
                  </a>
                </li>
              ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GamesSearchModal;
