import React from "react";
import { casinoSection } from "../../lib/data/casinoSection.data";
import { auraGames } from "../../lib/data/auraGames.data";
import Slider from "react-slick";

const CategorySlider = () => {
  const casinoData = { ...casinoSection, ...auraGames };

  const settings = {
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 6,
    slidesToScroll: 7,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <div>
      <div className="popular_game_sec ProviderSection">
        <h3>B11 Original</h3>
        <Slider {...settings}>
          {Object.values(casinoData?.b11).map((item, id) => {
            return (
              <div key={id}>
                <div className="game_box">
                  <a href={item.redirectUrl}>
                    <img src={item.imgUrl} alt={item.alt} />
                  </a>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="popular_game_sec ProviderSection">
        <h3>Top Picks</h3>
        <Slider {...settings}>
          {Object.values(casinoData?.popularGames).map((item, id) => {
            return (
              <div key={id}>
                <div className="game_box">
                  <a href={item.redirectUrl}>
                    <img src={item.imgUrl} alt={item.alt} />
                  </a>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="popular_game_sec ProviderSection">
        <h3>New Releases</h3>
        <Slider {...settings}>
          {Object.values(casinoData?.AndarBahar).map((item, id) => {
            return (
              <div key={id}>
                <div className="game_box">
                  <a href={item.redirectUrl}>
                    <img src={item.imgUrl} alt={item.alt} />
                  </a>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="popular_game_sec ProviderSection">
        <h3>Slots</h3>
        <Slider {...settings}>
          {Object.values(casinoData?.roulettetab).map((item, id) => {
            return (
              <div key={id}>
                <div className="game_box">
                  <a href={item.redirectUrl}>
                    <img src={item.imgUrl} alt={item.alt} />
                  </a>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="popular_game_sec ProviderSection">
        <h3>Game Shows</h3>
        <Slider {...settings}>
          {Object.values(casinoData?.dragontiger).map((item, id) => {
            return (
              <div key={id}>
                <div className="game_box">
                  <a href={item.redirectUrl}>
                    <img src={item.imgUrl} alt={item.alt} />
                  </a>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="popular_game_sec ProviderSection">
        <h3>Providers</h3>
        <Slider {...settings}>
          {Object.values(casinoData?.baccarattab).map((item, id) => {
            return (
              <div key={id}>
                <div className="game_box">
                  <a href={item.redirectUrl}>
                    <img src={item.imgUrl} alt={item.alt} />
                  </a>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="popular_game_sec ProviderSection">
        <h3>Live Casino</h3>
        <Slider {...settings}>
          {Object.values(casinoData?.livecasino).map((item, id) => {
            return (
              <div key={id}>
                <div className="game_box">
                  <a href={item.redirectUrl}>
                    <img src={item.imgUrl} alt={item.alt} />
                  </a>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="popular_game_sec ProviderSection">
        <h3>Table game</h3>
        <Slider {...settings}>
          {Object.values(casinoData?.tablegame).map((item, id) => {
            return (
              <div key={id}>
                <div className="game_box">
                  <a href={item.redirectUrl}>
                    <img src={item.imgUrl} alt={item.alt} />
                  </a>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default CategorySlider;
