import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from "react-router-dom";
import {
  mobileVerification,
  registerUser,
  sendOtpToMobile,
} from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { useParams, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Modal } from "react-bootstrap";
import { APP_CONST } from "../../../config/const";
import { Toaster } from "../../../utils/toaster";
import { parsePhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";

import LoginBanner from "../../../assets/images/banner/login.webp";
import LoginBannerMob from "../../../assets/images/banner/loginmob.webp";

const RegisterModal = ({
  show,
  handleClose,
  handleModalShow,
  agentCode: agentCodeProp,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [mobileDetails, setMobileDetails] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [referalCode, setReferalCode] = useState(location?.state?.referalCode);
  const [agentCode, setAgentCode] = useState(agentCodeProp);
  const [isReffered, setIsReffered] = useState(false);
  const [cookies, setCookie] = useCookies(["refferal"]);
  const [agentCookies, setAgentCookie] = useCookies(["agentCode"]);
  const { appDetails } = useSelector((state) => state.app);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtpValue] = useState("");

  const [registerConfig, setRegisterConfig] = useState({
    isOtpEnabled: useSelector((state) => state?.app?.appDetails) === 0,
    mobileDetails: {},
    isSendingOtp: false,
    isVerifyingOtp: false,
    isOtpSent: false,
    isOtpVerified: false,
    showPassword: false,
    showConfirmPassword: false,
  });
  const handleRegisterConfigChange = (config) => {
    setRegisterConfig((p) => ({ ...p, ...config }));
  };
  const sendOtp = () => {
    handleRegisterConfigChange({
      isSendingOtp: true,
    });
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    payload.mobile = phoneNumber.nationalNumber;
    payload.country_code = phoneNumber.countryCallingCode;

    dispatch(sendOtpToMobile(payload))
      .then((res) => {
        handleRegisterConfigChange({
          mobileDetails: payload,
          isOtpSent: true,
          isSendingOtp: false,
        });
        if (res?.data?.error === false) {
          Toaster(
            APP_CONST.TOAST_TYPE.SUCCESS,
            res?.data?.message || "OTP sent"
          );
        } else {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res?.data?.message);
        }
      })
      .catch((err) => {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err?.response?.data?.message);
        handleRegisterConfigChange({
          isSendingOtp: false,
        });
      });
  };
  const verifyOtp = () => {
    handleRegisterConfigChange({
      isVerifying: true,
    });
    dispatch(mobileVerification({ ...registerConfig.mobileDetails, otp }))
      .then((res) => {
        handleRegisterConfigChange({
          isVerifying: false,
          isOtpVerified: true,
        });
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP verified");
      })
      .catch((err) => {
        handleRegisterConfigChange({
          isVerifying: false,
        });
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
      });
  };
  useEffect(() => {
    getMobileDetails();
  }, []);

  const getMobileDetails = () => {
    const details =
      localStorage.getItem("mobile_details") &&
      JSON.parse(localStorage.getItem("mobile_details"));
    if (details) {
      setMobileDetails(details);
    }
  };
  const validateData = (data = null) => {
    const dataToValidate = data ? data : formData;
    const { name, username, password, confirmPassword, email } = dataToValidate;
    const newErrors = {};
    if (!name || name === "") {
      newErrors.name = "Name cannot be empty";
    }
    if (!username || username === "") {
      newErrors.userName = "User name cannot be empty";
    }
    if (username && (username.length < 4 || username.length > 15)) {
      newErrors.userName = "Username must be 4 to 15 characters.";
    }
    if (!/^[A-Za-z0-9 ]+$/.test(username)) {
      newErrors.userName = "Special characters are not allowed in username.";
    }
    if (appDetails?.IS_EMAIL) {
      if (!email || email === "") {
        newErrors.email = "Email cannot be empty";
      }
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    if (password && (password.length < 8 || password.length > 20)) {
      newErrors.password = "Password must be 8 to 20 characters.";
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm password cannot be empty";
    } else if (password != confirmPassword) {
      newErrors.confirmPassword = "Password and Confirm password must be same";
    }
    setErrors(newErrors);
    return newErrors;
  };
  const handleSubmit = async () => {
    let errorsData = validateData();
    if (Object.keys(errorsData).length > 0) {
      //setErrors(errorsData);
    } else {
      let dataToSend = { ...formData };
      delete dataToSend.confirmPassword;
      if (agentCode) {
        dataToSend.agent_code = agentCode;
      }
      dispatch(
        registerUser(
          {
            ...dataToSend,
            typeId: 3,
            parentId: 81026,
            mobile: registerConfig.mobileDetails?.mobile,
            country_code: registerConfig.mobileDetails.country_code,
          },
          navigate
        )
      );
      // checkIfExistingUserDetails(formData);
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
    validateData(tempFormData);
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <div className="modalSection">
        <div className="LoginBanner">
          <img src={LoginBanner} alt="" className="d-md-block d-none" />
          <img src={LoginBannerMob} alt="" className="d-md-none d-block" />
        </div>
        <div className="LoginRightPart">
          <Modal.Header
            closeButton
            closeVariant="white"
            onClick={() => handleClose()}
          >
            <Modal.Title> Sign Up</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="login-section">
              <div className="Login-rightPart">
                <div className="form-steps">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Full name*"
                        autoComplete="off"
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {(appDetails?.IS_EMAIL && (
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Email*"
                          autoComplete="off"
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )) ||
                      null}
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Control
                        type="text"
                        name="username"
                        placeholder="Choose Username*"
                        autoComplete="off"
                        onChange={handleChange}
                        isInvalid={!!errors.userName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.userName}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Choose Password*"
                        autoComplete="new-password"
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors?.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password*"
                        autoComplete="new-password"
                        onChange={handleChange}
                        isInvalid={!!errors.confirmPassword}
                      />
                      <span
                        className="icon"
                        onClick={() => setShowConfirmPassword((p) => !p)}
                      >
                        {showConfirmPassword ? <IoIosEyeOff /> : <IoIosEye />}
                      </span>
                      <Form.Control.Feedback type="invalid">
                        {errors?.confirmPassword}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {
                      <Form.Group
                        className="mb-3 position-relative"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type={"text"}
                          name="agent_code"
                          placeholder="Please enter agent code"
                          value={agentCode}
                          disabled={Boolean(agentCodeProp)}
                          onChange={(e) => setAgentCode(e.target.value)}
                        />
                      </Form.Group>
                    }

                    <div className="get-otp">
                      <Form.Group
                        className="position-relative mb-3 "
                        controlId="formBasicPassword"
                      >
                        <PhoneInput
                          country={"in"}
                          className="PhoneInput"
                          countryCodeEditable={false}
                          inputProps={{
                            name: "phone",
                            required: true,
                          }}
                          onChange={(phone) => {
                            setMobileNumber(phone);
                          }}
                        />
                      </Form.Group>

                      <Button
                        variant="primary"
                        onClick={sendOtp}
                        disabled={registerConfig.isSendingOtp}
                      >
                        {registerConfig.isSendingOtp
                          ? "Loading.."
                          : registerConfig.isOtpSent === true
                          ? "Resend OTP"
                          : "Get OTP"}
                      </Button>
                    </div>

                    <div className="verify-otp get-otp mb-3 ">
                      <Form.Group
                        className=" position-relative"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Enter OTP*"
                          onChange={(e) => setOtpValue(e.target.value)}
                        />
                      </Form.Group>

                      <Button
                        onClick={verifyOtp}
                        className="btn-primary"
                        disabled={
                          registerConfig.isVerifyingOtp ||
                          !registerConfig.isOtpSent ||
                          !otp
                        }
                      >
                        {!registerConfig.isOtpVerified
                          ? !registerConfig.isVerifyingOtp
                            ? "VERIFY OTP"
                            : "SUBMITTING"
                          : "VERIFIED"}
                      </Button>
                    </div>
                  </Form>
                  <Button
                    className="btn-primary"
                    variant=""
                    type="submit"
                    disabled={!registerConfig.isOtpVerified}
                    onClick={handleSubmit}
                    style={{ color: "white" }}
                  >
                    SUBMIT
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};
export default RegisterModal;
