export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoTabs = {
  popular: {
    OneDayTeenPattiPR: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/onedayteenpatti.webp",
      alt: "one day teen patti",
      name: "One Day Teen Patti",
    },
    AviatorPR: {
      redirectUrl: "/casino/spribe/aviator",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/aviator.webp",
      alt: "aviator",
      name: "Aviator",
    },
    AndarBaharLivePR: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/andarbahar.webp",
      alt: "andar bahar live",
      name: "Andar Bahar Lve",
    },
    Cards32PR: {
      redirectUrl: "/casino/ezg-32-cards",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/32cards.webp",
      alt: "32 cards",
      name: "32 Cards",
    },
    Victorious: {
      redirectUrl: "/casino/ezgne-victorious",
      code: "1100123                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/Victorious.webp",
      alt: "",
      name: "Victorious",
      providerName: "NetEnt",
    },
    Warlords: {
      redirectUrl: "/casino/ezgne-warlords-crystal-of-power",
      code: "1100125                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/warlords.webp",
      alt: "",
      name: "Warlords: Crystal of Power",
      providerName: "NetEnt",
    },
    WildWater: {
      redirectUrl: "/casino/ezgne-wild-water",
      code: "1100126                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/WildWater.webp",
      alt: "",
      name: "Wild Water",
      providerName: "NetEnt",
    },
    WildWorlds: {
      redirectUrl: "/casino/ezgne-wild-worlds",
      code: "1100127                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/wildworlds.webp",
      alt: "",
      name: "Wild Worlds",
      providerName: "NetEnt",
    },
    Squad4: {
      redirectUrl: "/casino/ezgrt-4-squad",
      code: "1100129                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/Squad4.webp",
      alt: "",
      name: "4 Squad",
      providerName: "Red Tiger",
    },
    Families5: {
      redirectUrl: "/casino/ezgrt-5-families",
      code: "1100130                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/families5.webp",
      alt: "",
      name: "5 Families",
      providerName: "Red Tiger",
    },
    AgentRoyale: {
      redirectUrl: "/casino/ezgrt-agent-royale",
      code: "1100131                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/AgentRoyale.webp",
      alt: "",
      name: "Agent Royale",
      providerName: "Red Tiger",
    },
    ArcadeBomb: {
      redirectUrl: "/casino/ezgrt-arcade-bomb",
      code: "1100132                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/arcadebomb.webp",
      alt: "",
      name: "Arcade Bomb",
      providerName: "Red Tiger",
    },
    Atlantis: {
      redirectUrl: "/casino/ezgrt-atlantis",
      code: "1100133                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/Atlantis.webp",
      alt: "",
      name: "Atlantis",
      providerName: "Red Tiger",
    },
    CrazyTimePR: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/crazytime.webp",
      alt: "crazy time",
      name: "Crazy Time",
    },
    DoubleBallRoulettePR: {
      redirectUrl: "/casino/ezgevo-double-ball-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/doubleballroulette.webp",
      alt: "double ball roulette",
      name: "Double Ball Roulette",
    },
    DragonTiger1PR: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/dragontiger.webp",
      alt: "dragon tiger 1",
      name: "Dragon Tiger 1",
    },
    ItalianRoulettePR: {
      redirectUrl: "/casino/ezg-italian-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/italianroulette.webp",
      alt: "italian roulette",
      name: "Italian Roulette",
    },
    Lucky7PR: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/lucky7.webp",
      alt: "seven lucky",
      name: "Seven Lucky",
    },
  },
  exclusive: {
    UltimateRouletteEZ: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/UltimateRouletteEZ.webp",
      alt: "ultimate roulette",
      name: "Ultimate Roulette",
    },
    DiamondRouletteEZ: {
      redirectUrl: "/casino/ezg-diamond-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/DiamondRouletteEZ.webp",
      alt: "diamond roulette",
      name: "Diamond Roulette",
    },
    NamasteRouletteEZ: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/NamasteRouletteEZ.webp",
      alt: "namaste roulette",
      name: "Namaste Roulette",
    },
    AutoRouletteEZ: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/AutoRouletteEZ.webp",
      alt: "auto roulette",
      name: "Auto Roulette",
    },
    SpeedAutoRouletteEZ: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/SpeedAutoRouletteEZ.webp",
      alt: "speed auto roulette",
      name: "Speed Auto Roulette",
    },
    OracleRoulette2EZ: {
      redirectUrl: "/casino/ezg-oracle-roulette-2",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/OracleRoulette2EZ.webp",
      alt: "oracle roulette 2",
      name: "Oracle Roulette 2",
    },
    ItalianRouletteEZ: {
      redirectUrl: "/casino/ezg-italian-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/ItalianRouletteEZ.webp",
      alt: "italian roulette",
      name: "Italian Roulette",
    },
    SpanishRouletteEZ: {
      redirectUrl: "/casino/ezg-spanish-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/SpanishRouletteEZ.webp",
      alt: "spanish roulette",
      name: "Spanish Roulette",
    },
    InstantRouletteEvo: {
      redirectUrl: "/casino/ezgevo-instant-roulette",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/InstantRouletteEvo.webp",
      alt: "instant roulette",
      name: "Instant Roulette",
    },
    DragonTigerEvo: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/DragonTigerEvo.webp",
      alt: "dragon tiger evo",
      name: "Dragon Tiger Evo",
    },
    BaccaratAEvo: {
      redirectUrl: "/casino/ezgevo-baccarat-a",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/BaccaratAEvo.webp",
      alt: "baccarat a",
      name: "Baccarat A",
    },
    BlackjackAEvo: {
      redirectUrl: "/casino/ezgevo-blackjack-a",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/BlackjackAEvo.webp",
      alt: "blackjack a",
      name: "Blackjack A",
    },
    CrazyTimeEvo: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/CrazyTimeEvo.webp",
      alt: "crazy time",
      name: "Crazy Time",
    },
    MegaballEvo: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/megaball.webp",
      alt: "megaball",
      name: "Megaball",
    },
    SuperSicBoEvo: {
      redirectUrl: "/casino/ezgevo-super-sic-bo",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/SuperSicBoEvo.webp",
      alt: "super sic bo",
      name: "Super Sic Bo",
    },
    Poker: {
      code: "67564",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Poker",
      name: "Poker",
      providerName: "aura",
      imgUrl: "./images/aura/Poker.webp",
      redirectUrl: "/casino/aura-poker",
    },
    SuperOver: {
      code: "67720",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Super Over",
      name: "Super Over",
      providerName: "aura",
      imgUrl: "./images/aura/SuperOver.webp",
      redirectUrl: "/casino/aura-sper-over",
    },
    Matka: {
      code: "92037",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Matka",
      name: "Matka",
      providerName: "aura",
      imgUrl: "./images/aura/Matka.webp",
      redirectUrl: "/casino/aura-matka",
    },
    Baccarat: {
      code: "92038",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Baccarat",
      name: "Baccarat",
      providerName: "aura",
      imgUrl: "./images/aura/Baccarat.webp",
      redirectUrl: "/casino/aura-baccarat",
    },
    Trio: {
      code: "67610",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Trio",
      name: "Trio",
      providerName: "aura",
      imgUrl: "./images/aura/Trio.webp",
      redirectUrl: "/casino/aura-trio",
    },
    CardsJudgement3: {
      code: "67670",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "3 Cards Judgement",
      name: "3 Cards Judgement",
      providerName: "aura",
      imgUrl: "./images/aura/3CardJudgement.jpg",
      redirectUrl: "/casino/aura-3-cards-judgement",
    },
    Race2020: {
      code: "90100",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Race 20-20",
      name: "Race 20-20",
      providerName: "aura",
      imgUrl: "./images/aura/Race20-20.webp",
      redirectUrl: "/casino/aura-race-20-20",
    },
    CardBaccarat29: {
      code: "67690",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "29 Card Baccarat",
      name: "29 Card Baccarat",
      providerName: "aura",
      imgUrl: "./images/aura/CardBaccarat29.webp",
      redirectUrl: "/casino/aura-29-card-baccarat",
    },
    TeenpattiOpen: {
      code: "67640",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti Open",
      name: "Teenpatti Open",
      providerName: "aura",
      imgUrl: "./images/aura/teenpaatiopen.webp",
      redirectUrl: "/casino/aura-teenpatti-open",
    },
    Raceto17: {
      code: "67710",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Race to 17",
      name: "Race to 17",
      providerName: "aura",
      imgUrl: "./images/aura/Raceto17.webp",
      redirectUrl: "/casino/aura-race-to-17",
    },
    Sixplayerpoker: {
      code: "67565",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Six player poker",
      name: "Six player poker",
      providerName: "aura",
      imgUrl: "./images/aura/Sixplayerpoker.webp",
      redirectUrl: "/casino/aura-six-player-poker",
    },
    CasinoMeter: {
      code: "67575",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Casino Meter",
      name: "Casino Meter",
      providerName: "aura",
      imgUrl: "./images/aura/CasinoMeter.webp",
      redirectUrl: "/casino/aura-casino-meter",
    },
    TheTrap: {
      code: "67680",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "The Trap",
      name: "The Trap",
      providerName: "aura",
      imgUrl: "./images/aura/TheTrap.webp",
      redirectUrl: "/casino/aura-the-trap",
    },
  },
  ezugi: {
    // UltimateRouletteEZ: {
    //   redirectUrl: "/casino/ezg-ultimate-roulette",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "Ezugi",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/casino/ezugi/Speed-Roulette1.png",
    //   alt: "ultimate roulette",
    //   name: "Ultimate Roulette",
    // },
    // DiamondRouletteEZ: {
    //   redirectUrl: "/casino/ezugi/DiamondRoulette",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "Ezugi",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/casino/ezugi/Diamond-roulette1.png",
    //   alt: "diamond roulette",
    //   name: "Diamond Roulette",
    // },
    // NamasteRouletteEZ: {
    //   redirectUrl: "/casino/ezugi/NamasteRoulette",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "Ezugi",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/casino/ezugi/Namaste-roulette1.png",
    //   alt: "namaste roulette",
    //   name: "Namaste Roulette",
    // },
    AutoRouletteEZ: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Auto-roulette.png",
      alt: "auto roulette",
      name: "Auto Roulette",
    },
    SpeedAutoRouletteEZ: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Speed-auto-roulette.png",
      alt: "speed auto roulette",
      name: "Speed Auto Roulette",
    },
    OracleRoulette2EZ: {
      redirectUrl: "/casino/ezg-oracle-roulette-2",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Oracle-360-roulette.png",
      alt: "oracle roulette 2",
      name: "Oracle Roulette 2",
    },
    ItalianRouletteEZ: {
      redirectUrl: "/casino/ezg-italian-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Italian-roulette1.png",
      alt: "italian roulette",
      name: "Italian Roulette",
    },
    SpanishRouletteEZ: {
      redirectUrl: "/casino/ezg-spanish-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Spanish-roulette.png",
      alt: "spanish roulette",
      name: "Spanish Roulette",
    },
  },
  evolution: {
    InstantRouletteEvo: {
      redirectUrl: "/casino/ezgevo-instant-roulette",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/Instantroulette.png",
      alt: "instant roulette",
      name: "Instant Roulette",
    },
    // DragonTigerEvo: {
    //   redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
    //   code: "",
    //   casino: "evolution",
    //   provider: "Evolution",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/casino/evolution/DragonTiger.png",
    //   alt: "dragon tiger evo",
    //   name: "Dragon Tiger Evo",
    // },
    BaccaratAEvo: {
      redirectUrl: "/casino/ezgevo-baccarat-a",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/Baccart.png",
      alt: "baccarat a",
      name: "Baccarat A",
    },
    BlackjackAEvo: {
      redirectUrl: "/casino/ezgevo-blackjack-a",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/Blackjack.png",
      alt: "blackjack a",
      name: "Blackjack A",
    },
    CrazyTimeEvo: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/CrazyTime.png",
      alt: "crazy time",
      name: "Crazy Time",
    },
    MegaballEvo: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/Megaball.png",
      alt: "megaball",
      name: "Megaball",
    },
    SuperSicBoEvo: {
      redirectUrl: "/casino/ezgevo-super-sic-bo",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/Supersicbo.png",
      alt: "super sic bo",
      name: "Super Sic Bo",
    },
  },
  spribe: {
    mines: {
      redirectUrl: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/Mines.webp",
      alt: "mines",
      name: "Mines",
    },
    goal: {
      redirectUrl: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/Goal.webp",
      alt: "goal",
      name: "Goal",
    },
    dice: {
      redirectUrl: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/Dice.webp",
      alt: "dice",
      name: "Dice",
    },
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/Aviator.webp",
      alt: "aviator",
      name: "Aviator",
    },
    plinko: {
      redirectUrl: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/Plinko.webp",
      alt: "plinko",
      name: "Plinko",
    },
    miniroulette: {
      redirectUrl: "/casino/spribe/miniroulette",
      code: "miniroulette",
      casino: "spribe",
      provider: "miniroulette",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/miniroulette.webp",
      alt: "mini roulette",
      name: "Mini Roulette",
    },
    hilo: {
      redirectUrl: "/casino/spribe/hilo",
      code: "hilo",
      casino: "spribe",
      provider: "hilo",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/hilo.webp",
      alt: "hilo",
      name: "Hilo",
    },
    Blastoff: {
      code: "67722",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Blastoff",
      name: "Blastoff",
      providerName: "aura",
      imgUrl: "./images/spribe/Blast-off.webp",
      redirectUrl: "/casino/aura-blastoff",
    },
    FortuneWheel: {
      code: "70001",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Fortune Wheel",
      name: "Fortune Wheel",
      providerName: "aura",
      imgUrl: "./images/spribe/FortumeWheel.webp",
      redirectUrl: "/casino/aura-fortune-wheel",
    },
    CoinToss: {
      code: "70003",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Coin Toss",
      name: "Coin Toss",
      providerName: "aura",
      imgUrl: "./images/spribe/cointoss.webp",
      redirectUrl: "/casino/aura-coin-toss",
    },
  },
  aura: {
    BaccaratAura: {
      redirectUrl: "/casino/aura-baccarat",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/aura/Baccarat-new.webp",
      alt: "baccarat",
      name: "Baccarat",
    },
    DreamCatcherAura: {
      redirectUrl: "/casino/aura-dream-catcher",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/aura/Dream-Catcher.webp",
      alt: "dream catcher",
      name: "Dream Catcher",
    },
    HiLowAura: {
      redirectUrl: "/casino/aura-hi-low",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/aura/Hi-Low.webp",
      alt: "hi low",
      name: "Hi Low",
    },
    MatkaAura: {
      redirectUrl: "/casino/aura-matka",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/aura/Matka.webp",
      alt: "matka",
      name: "Matka",
    },
    // TeenpattiOpenAura: {
    //   redirectUrl: "/casino/aura-teenpatti-open",
    //   code: "",
    //   casino: "Aura Gaming",
    //   provider: "Aura",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/aura/OpenTeenPatti.webp",
    //   alt: "teenpatti open",
    //   name: "Teenpatti Open",
    // },
    Race2020Aura: {
      redirectUrl: "/casino/aura-race-20-20",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/aura/Race-20-20.webp",
      alt: "race 20 20",
      name: "Race 20 20",
    },
    TrioAura: {
      redirectUrl: "/casino/aura-trio",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/aura/Trio-new.webp",
      alt: "trio",
      name: "Trio",
    },
    TeenpattiT20: {
      code: "56768",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti T20",
      name: "Teenpatti T20",
      providerName: "aura",
      imgUrl: "./images/aura/TeenpattiT20.webp",
      redirectUrl: "/casino/aura-teenpatti-t20",
    },
    DragonTiger: {
      code: "98790",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Dragon Tiger",
      name: "Dragon Tiger",
      providerName: "aura",
      imgUrl: "./images/aura/Dragon-Tiger.webp",
      redirectUrl: "/casino/aura-dragon-tiger",
    },
  },
  auracardgames: {
    Poker: {
      code: "67564",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Poker",
      name: "Poker",
      providerName: "aura",
      imgUrl: "./images/aura/Poker.webp",
      redirectUrl: "/casino/aura-poker",
    },
    SuperOver: {
      code: "67720",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Super Over",
      name: "Super Over",
      providerName: "aura",
      imgUrl: "./images/aura/SuperOver.webp",
      redirectUrl: "/casino/aura-sper-over",
    },
    Matka: {
      code: "92037",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Matka",
      name: "Matka",
      providerName: "aura",
      imgUrl: "./images/aura/Matka.webp",
      redirectUrl: "/casino/aura-matka",
    },
    Baccarat: {
      code: "92038",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Baccarat",
      name: "Baccarat",
      providerName: "aura",
      imgUrl: "./images/aura/Baccarat-new.webp",
      redirectUrl: "/casino/aura-baccarat",
    },
    Trio: {
      code: "67610",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Trio",
      name: "Trio",
      providerName: "aura",
      imgUrl: "./images/aura/Trio-new.webp",
      redirectUrl: "/casino/aura-trio",
    },
    CardsJudgement3: {
      code: "67670",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "3 Cards Judgement",
      name: "3 Cards Judgement",
      providerName: "aura",
      imgUrl: "./images/aura/3cardsJudgment.webp",
      redirectUrl: "/casino/aura-3-cards-judgement",
    },
    Race2020: {
      code: "90100",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Race 20-20",
      name: "Race 20-20",
      providerName: "aura",
      imgUrl: "./images/aura/Race-20-20.webp",
      redirectUrl: "/casino/aura-race-20-20",
    },
    CardBaccarat29: {
      code: "67690",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "29 Card Baccarat",
      name: "29 Card Baccarat",
      providerName: "aura",
      imgUrl: "./images/aura/29CardsBaccarat.webp",
      redirectUrl: "/casino/aura-29-card-baccarat",
    },
    TeenpattiOpen: {
      code: "67640",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti Open",
      name: "Teenpatti Open",
      providerName: "aura",
      imgUrl: "./images/aura/OpenTeenPatti.webp",
      redirectUrl: "/casino/aura-teenpatti-open",
    },
    Raceto17: {
      code: "67710",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Race to 17",
      name: "Race to 17",
      providerName: "aura",
      imgUrl: "./images/aura/Raceto17.webp",
      redirectUrl: "/casino/aura-race-to-17",
    },
    Sixplayerpoker: {
      code: "67565",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Six player poker",
      name: "Six player poker",
      providerName: "aura",
      imgUrl: "./images/aura/6playerpoker.webp",
      redirectUrl: "/casino/aura-six-player-poker",
    },
    CasinoMeter: {
      code: "67575",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Casino Meter",
      name: "Casino Meter",
      providerName: "aura",
      imgUrl: "./images/aura/CasinoMeter.webp",
      redirectUrl: "/casino/aura-casino-meter",
    },
    TheTrap: {
      code: "67680",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "The Trap",
      name: "The Trap",
      providerName: "aura",
      imgUrl: "./images/aura/TheTrap.webp",
      redirectUrl: "/casino/aura-the-trap",
    },
  },
  sports: {
    InplayCricket: {
      redirectUrl: "/spmsports/cricket",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/In-play.png",
      alt: "inplay",
      name: "Inplay",
    },
    Cricket: {
      redirectUrl: "/spmsports/cricket",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/cricket.png",
      alt: "cricket",
      name: "Cricket",
    },
    Soccer: {
      redirectUrl: "/spmsports/soccer",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Soccer.png",
      alt: "soccer",
      name: "Soccer",
    },
    Tennis: {
      redirectUrl: "/spmsports/tennis",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Tennis.png",
      alt: "tennis",
      name: "Tennis",
    },
    InplayCricket2: {
      redirectUrl: "/spmsports/cricket",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/In-play.png",
      alt: "inplay",
      name: "Inplay",
    },
    Cricket2: {
      redirectUrl: "/spmsports/cricket",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/cricket.png",
      alt: "cricket",
      name: "Cricket",
    },
    Soccer2: {
      redirectUrl: "/spmsports/soccer",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Soccer.png",
      alt: "soccer",
      name: "Soccer",
    },
    Tennis2: {
      redirectUrl: "/spmsports/tennis",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Tennis.png",
      alt: "tennis",
      name: "Tennis",
    },
  },
  providers: {
    ezugi: {
      redirectUrl: "/casino/ezugi",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/Ezugi.jpg",
      name: "Ezugi",
      quotes:
        "Experience the excitement at Ezugi Casino, where every game is live and thrilling. Join us to enjoy real-time action and endless opportunities for big wins!",
    },
    evolution: {
      redirectUrl: "/casino/evolution",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/Evolution.jpg",
      name: "Evolution",
      quotes:
        "Join Evolution Casino for an unmatched gaming experience where innovation meets excitement. Every game is a new opportunity, and every win evolves your adventure!",
    },
    vivo: {
      redirectUrl: "/casino/vivo",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/vivo.jpg",
      name: "Vivo",
      quotes:
        "Experience the ultimate thrill at Vivo Gaming Casino, where cutting-edge technology meets unparalleled excitement. Play now and turn every game into a winning adventure!",
    },
    qtech: {
      redirectUrl: "/casino/qtech",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/Qtech.jpg",
      name: "Qtech",
      quotes:
        "Discover the future of gaming at QTech Casino, where innovation and excitement meet. Dive into a world of thrilling games and endless opportunities for big wins!",
    },
    supernowa: {
      redirectUrl: "/casino/supernowa",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/Supernowa.jpg",
      name: "Supernowa",
      quotes:
        "Unleash your winning potential at Supernowa Casino, where every game shines bright with excitement and every spin brings you closer to extraordinary rewards!",
    },
    xpg: {
      redirectUrl: "/casino/xpg",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/XPG.jpg",
      name: "XPG",
      quotes:
        "Step into the world of XPG Casino, where cutting-edge gaming meets unmatched excitement. Every game is a new adventure, and every win brings you closer to greatness!",
    },
    worldcasino: {
      redirectUrl: "/casino/worldcasino",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/WorldCasino.jpg",
      name: "World Casino",
      quotes:
        "Discover endless excitement at World Casino, where every game is a journey to big wins and unforgettable moments. Join us and make your mark on the world of gaming!",
    },
    // aesexy: {
    //   redirectUrl: "/casino/aesexy",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/providers/aesexy.jpg",
    //   name: "AE Sexy",
    //   quotes:
    //     "Experience the thrill and elegance of AE Sexy, where every game is a captivating adventure, and every win adds a touch of allure. Join us for unforgettable moments!",
    // },
    powergames: {
      redirectUrl: "/casino/powergames",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/powergames.jpg",
      name: "Power Games",
      quotes:
        "Unleash your potential and experience the thrill of winning at Power Games Casino, where every game is a new adventure and every spin brings you closer to your next big win!",
    },
  },
  slotsgames: {
    SerengetiKings: {
      redirectUrl: "/casino/ezgne-secrets-of-atlantis",
      code: "1100121                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/SerengetiKings.webp",
      alt: "",
      name: "Serengeti Kings",
      providerName: "NetEnt",
    },
    SpaceWars: {
      redirectUrl: "/casino/ezgne-space-wars",
      code: "1100122                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/spacewars.webp",
      alt: "",
      name: "Space Wars",
      providerName: "NetEnt",
    },
    Victorious: {
      redirectUrl: "/casino/ezgne-victorious",
      code: "1100123                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/Victorious.webp",
      alt: "",
      name: "Victorious",
      providerName: "NetEnt",
    },
    Warlords: {
      redirectUrl: "/casino/ezgne-warlords-crystal-of-power",
      code: "1100125                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/warlords.webp",
      alt: "",
      name: "Warlords: Crystal of Power",
      providerName: "NetEnt",
    },
    WildWater: {
      redirectUrl: "/casino/ezgne-wild-water",
      code: "1100126                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/WildWater.webp",
      alt: "",
      name: "Wild Water",
      providerName: "NetEnt",
    },
    WildWorlds: {
      redirectUrl: "/casino/ezgne-wild-worlds",
      code: "1100127                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/wildworlds.webp",
      alt: "",
      name: "Wild Worlds",
      providerName: "NetEnt",
    },
    Squad4: {
      redirectUrl: "/casino/ezgrt-4-squad",
      code: "1100129                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/Squad4.webp",
      alt: "",
      name: "4 Squad",
      providerName: "Red Tiger",
    },
    Families5: {
      redirectUrl: "/casino/ezgrt-5-families",
      code: "1100130                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/families5.webp",
      alt: "",
      name: "5 Families",
      providerName: "Red Tiger",
    },
    AgentRoyale: {
      redirectUrl: "/casino/ezgrt-agent-royale",
      code: "1100131                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/AgentRoyale.webp",
      alt: "",
      name: "Agent Royale",
      providerName: "Red Tiger",
    },
    ArcadeBomb: {
      redirectUrl: "/casino/ezgrt-arcade-bomb",
      code: "1100132                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/arcadebomb.webp",
      alt: "",
      name: "Arcade Bomb",
      providerName: "Red Tiger",
    },
    Atlantis: {
      redirectUrl: "/casino/ezgrt-atlantis",
      code: "1100133                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/Atlantis.webp",
      alt: "",
      name: "Atlantis",
      providerName: "Red Tiger",
    },
    AztecSpins: {
      redirectUrl: "/casino/ezgrt-aztec-spins",
      code: "1100134                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/aztecspins.webp",
      alt: "",
      name: "Aztec Spins",
      providerName: "Red Tiger",
    },
    CinderellaBall: {
      redirectUrl: "/casino/ezgrt-cinderellas-ball",
      code: "1100135                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/CinderellaBall.webp",
      alt: "",
      name: "Cinderella`s Ball",
      providerName: "Red Tiger",
    },
    CirquedelaFortune: {
      redirectUrl: "/casino/ezgrt-cirque-de-la-fortune",
      code: "1100136                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/CirquedelaFortune.webp",
      alt: "",
      name: "Cirque de la Fortune",
      providerName: "Red Tiger",
    },
    ClashoftheBeasts: {
      redirectUrl: "/casino/ezgrt-clash-of-the-beasts",
      code: "1100137                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/ClashoftheBeasts.webp",
      alt: "",
      name: "Clash of the Beasts",
      providerName: "Red Tiger",
    },
    CrazyGenie: {
      redirectUrl: "/casino/ezgrt-crazy-genie",
      code: "1100138                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/CrazyGenie.webp",
      alt: "",
      name: "Crazy Genie",
      providerName: "Red Tiger",
    },
    CrystalMirror: {
      redirectUrl: "/casino/ezgrt-crystal-mirror",
      code: "1100139                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/CrystalMirror.webp",
      alt: "",
      name: "Crystal Mirror",
      providerName: "Red Tiger",
    },
    DaVinciMystery: {
      redirectUrl: "/casino/ezgrt-da-vincis-mystery",
      code: "1100140                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/DaVinciMystery.webp",
      alt: "",
      name: "Da Vinci`s Mystery",
      providerName: "Red Tiger",
    },
    DevilNumber: {
      redirectUrl: "/casino/ezgrt-devils-number",
      code: "1100141                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/devilnumber.webp",
      alt: "",
      name: "Devil`s Number",
      providerName: "Red Tiger",
    },
    DiceDice: {
      redirectUrl: "/casino/ezgrt-dice-dice-dice",
      code: "1100142                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/DiceDice.webp",
      alt: "",
      name: "Dice Dice Dice",
      providerName: "Red Tiger",
    },
    FiveStarsPowerReels: {
      redirectUrl: "/casino/ezgrt-five-stars-power-reels",
      code: "1100144                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/FiveStarpowerreel.webp",
      alt: "",
      name: "Five Stars Power Reels",
      providerName: "Red Tiger",
    },
    GemsGoneWild: {
      redirectUrl: "/casino/ezgrt-gems-gone-wild",
      code: "1100145                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/GemsGoneWild.webp",
      alt: "",
      name: "Gems Gone Wild",
      providerName: "Red Tiger",
    },
    GemsGoneWildPowerReels: {
      redirectUrl: "/casino/ezgrt-gems-gone-wild-power-reels",
      code: "1100146                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/GemsGoneWildPowerReels.webp",
      alt: "",
      name: "Gems Gone Wild Power Reels",
      providerName: "Red Tiger",
    },
    GoldenLeprechaunMegaways: {
      redirectUrl: "/casino/ezgrt-golden-leprechaun-megaways",
      code: "1100148                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/GoldenLeprechaunMegaways.webp",
      alt: "",

      name: "Golden Leprechaun Megaways",
      providerName: "Red Tiger",
    },
    HoardofPoseidon: {
      redirectUrl: "/casino/ezgrt-hoard-of-poseidon",
      code: "1100151                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/HoardofPoseidon.webp",
      alt: "",
      name: "Hoard of Poseidon",
      providerName: "Red Tiger",
    },
    JewelScarabs: {
      redirectUrl: "/casino/ezgrt-jewel-scarabs",
      code: "1100152                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/JewelScarabs.webp",
      alt: "",
      name: "Jewel Scarabs",
      providerName: "Red Tiger",
    },
    JingleBellsPowerReels: {
      redirectUrl: "/casino/ezgrt-jingle-bells-power-reels",
      code: "1100153                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/JingleBellsPowerReels.webp",
      alt: "",
      name: "Jingle Bells Power Reels",
      providerName: "Red Tiger",
    },
    LegendofAthena: {
      redirectUrl: "/casino/ezgrt-legend-of-athena",
      code: "1100154                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/LegendofAthena.webp",
      alt: "",
      name: "Legend of Athena",
      providerName: "Red Tiger",
    },
    LuckyHalloween: {
      redirectUrl: "/casino/ezgrt-lucky-halloween",
      code: "1100155                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/LuckyHalloween.webp",
      alt: "",
      name: "Lucky Halloween",
      providerName: "Red Tiger",
    },
    LuckyLittleDevil: {
      redirectUrl: "/casino/ezgrt-lucky-little-devil",
      code: "1100156                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/slots/LuckyLittleDevil.webp",
      alt: "",
      name: "Lucky Little Devil",
      providerName: "Red Tiger",
    },
  },
};
