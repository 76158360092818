import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import InplayIcon from "./../../assets/images/icons/InPlay.webp";
import CricketIcon from "./../../assets/images/icons/cricket.webp";
import SoccerIcon from "./../../assets/images/icons/soccer.webp";
import TennisIcon from "./../../assets/images/icons/tennis.webp";
import AviatorIcon from "./../../assets/images/icons/Aviator1.webp";
import EvolutionIcon from "./../../assets/images/icons/Evolution.webp";
import EzugiIcon from "./../../assets/images/icons/Ezugi.webp";
import XPGNew from "./../../assets/images/icons/XPG.webp";
import VivoNew from "./../../assets/images/icons/Vivo.webp";
import QtechNew from "./../../assets/images/icons/Qtech.webp";
import Sportsbook from "./../../assets/images/icons/sportsbook.webp";
import SlotsIconNew from "./../../assets/images/icons/slot-machine.webp";
import Kingmaker from "./../../assets/images/icons/KingMaker.webp";
import CasinoIcon from "./../../assets/images/icons/card-games.webp";
import SportsIcon from "./../../assets/images/icons/Sports.webp";
import Mines from "./../../assets/images/icons/Mines.webp";
import HotGames from "./../../assets/images/icons/download.png";

import PromotionIcon from "./../../assets/images/icons/Promotion.webp";
import BonusIcon from "./../../assets/images/icons/Bonus.webp";
import TermsnConIcon from "./../../assets/images/icons/termsandcondirtions.webp";
import ResponsibleGamingIcon from "./../../assets/images/icons/responsible.webp";
import FairplayIcon from "./../../assets/images/icons/Fairplay.webp";
import PrivacyPolicyIcon from "./../../assets/images/icons/PrivacyPolicy.webp";
import AboutusIcon from "./../../assets/images/icons/info.png";
import ContactusIcon from "./../../assets/images/icons/ContactUs.webp";
import GamesRulesIcon from "./../../assets/images/icons/gamesrules.webp";
import AffiliatesIcon from "./../../assets/images/icons/affiliate.webp";

import { Accordion } from "react-bootstrap";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const htmlElement = document.documentElement;
  const location = useLocation();
  useEffect(() => {
    htmlElement.style.overflow = "auto";
    document.body.classList.remove("leftbat-opened");
  }, [location]);

  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      <div className="leftbarSec">
        <ul>
          <li className="games_link">
            <ul>
              <li className="mainOption">
                <div
                  onClick={() => {
                    navigate("/casino/aura-mines");
                  }}
                >
                  <div className="img-box">
                    <img src={Mines} alt="Inplay Icon" />
                  </div>
                  <span>
                    Mines! <img src={HotGames} />
                  </span>
                </div>
              </li>
            </ul>
            {/* <h6>Quick Links</h6> */}
            <Accordion className="sidebaraccordian">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div
                    onClick={() => {
                      if (isAuth) {
                        navigate("/spmsports/cricket", {
                          state: { selectedTab: "1" },
                        });
                      } else {
                        navigate("/spmsports/cricket", {
                          state: { selectedTab: "1" },
                        });
                      }
                    }}
                  >
                    <div className="img-box">
                      <img src={SportsIcon} alt="Inplay Icon" />
                    </div>
                    <span>Sports</span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      <a href="/spmsports/live">
                        <img src={InplayIcon} alt="Inplay Icon" />
                        <span>In-play</span>
                      </a>
                    </li>
                    <li>
                      <a href="/spmsports/cricket">
                        <img src={CricketIcon} />
                        <span className="game-name">Cricket</span>
                      </a>
                    </li>
                    <li>
                      <a href="/spmsports/soccer">
                        <img src={SoccerIcon} />
                        <span className="game-name">Soccer</span>
                      </a>
                    </li>
                    <li>
                      <a href="/spmsports/tennis">
                        <img src={TennisIcon} />
                        <span className="game-name">Tennis</span>
                      </a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino");
                  }}
                >
                  <div className="img-box">
                    <img src={CasinoIcon} alt="Inplay Icon" />
                  </div>
                  <span>Casino</span>
                </div>
              </li>

              <li>
                <div
                  onClick={() => {
                    navigate("/betby");
                  }}
                >
                  <div className="img-box">
                    <img src={Sportsbook} alt="Casino Icon" />
                  </div>
                  <span>Sportsbook</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/slots");
                  }}
                >
                  <div className="img-box">
                    <img src={SlotsIconNew} alt="Casino Icon" />
                  </div>
                  <span>Slots</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/kingmaker");
                  }}
                >
                  <div className="img-box">
                    <img src={Kingmaker} alt="Casino Icon" />
                  </div>
                  <span>Kingmaker</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/supernowa");
                  }}
                >
                  <div className="img-box">
                    <img src={CasinoIcon} alt="Casino Icon" />
                  </div>
                  <span>Supernowa</span>
                </div>
              </li>
            </ul>
          </li>

          <li className="games_link">
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/spribe/aviator");
                  }}
                >
                  <div className="img-box">
                    <img src={AviatorIcon} alt="Casino Icon" />
                  </div>
                  <span>Aviator</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/evolution");
                  }}
                >
                  <div className="img-box">
                    <img src={EvolutionIcon} alt="Casino Icon" />
                  </div>
                  <span>Evolution</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/ezugi");
                  }}
                >
                  <div className="img-box">
                    <img src={EzugiIcon} alt="Casino Icon" />
                  </div>
                  <span>Ezugi</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/xpg");
                  }}
                >
                  <div className="img-box">
                    <img src={XPGNew} alt="Casino Icon" />
                  </div>
                  <span>XPG</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/vivo");
                  }}
                >
                  <div className="img-box">
                    <img src={VivoNew} alt="Casino Icon" />
                  </div>
                  <span>Vivo</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/qtech");
                  }}
                >
                  <div className="img-box">
                    <img src={QtechNew} alt="Casino Icon" />
                  </div>
                  <span>Qtech</span>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/promotion");
                  }}
                >
                  <div className="img-box">
                    <img src={PromotionIcon} alt="Casino Icon" />
                  </div>
                  <span>Promotion</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/bonus");
                  }}
                >
                  <div className="img-box">
                    <img src={BonusIcon} alt="Casino Icon" />
                  </div>
                  <span>Bonus</span>
                </div>
              </li>
            </ul>
            <ul className="d-md-none d-block">
              <li>
                <div
                  onClick={() => {
                    navigate("/affiliates");
                  }}
                >
                  <div className="img-box">
                    <img src={AffiliatesIcon} alt="Casino Icon" />
                  </div>
                  <span>Affiliate</span>
                </div>
              </li>
              <Accordion className="sidebaraccordian">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div
                      onClick={() => {
                        if (isAuth) {
                          navigate("/spmsports/cricket", {
                            state: { selectedTab: "1" },
                          });
                        } else {
                          navigate("/spmsports/cricket", {
                            state: { selectedTab: "1" },
                          });
                        }
                      }}
                    >
                      <div className="img-box">
                        <img src={AboutusIcon} alt="Inplay Icon" />
                      </div>
                      <span>Terms</span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <a href="/contactus">
                          <img src={ContactusIcon} alt="Inplay Icon" />

                          <span>Contact Us</span>
                        </a>
                      </li>
                      <li>
                        <a href="/privacypolicy">
                          <img src={PrivacyPolicyIcon} />
                          <span className="game-name">Privacy Policy</span>
                        </a>
                      </li>
                      <li>
                        <a href="/responsiblegame">
                          <img src={ResponsibleGamingIcon} />
                          <span className="game-name">Responsible Gaming</span>
                        </a>
                      </li>
                      <li>
                        <a href="/fairplay">
                          <img src={FairplayIcon} />
                          <span className="game-name">Fairplay</span>
                        </a>
                      </li>
                      <li>
                        <a href="/gamesrule">
                          <img src={GamesRulesIcon} />
                          <span className="game-name">Games Rules</span>
                        </a>
                      </li>
                      <li>
                        <a href="/terms">
                          <img src={TermsnConIcon} />
                          <span className="game-name">
                            Terms and Conditions
                          </span>
                        </a>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
//
