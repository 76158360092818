export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoSection = {
  b11: {
    Aviator: {
      redirectUrl: "/casino/spribe-aviator",
      imgUrl: "./images/b11/Aviator.webp",
      name: "Aviator",
      providerName: "Spribe",
    },
    AviatorAura: {
      redirectUrl: "/casino/aura-aviator",
      imgUrl: "./images/b11/Aviator-Aura.webp",
      name: "Aviator Aura",
      providerName: "Aura",
    },
    Blastoff: {
      redirectUrl: "/casino/aura-blastoff",
      imgUrl: "./images/b11/Blast-off(Aura).webp",
      name: "Blast-off",
      providerName: "Aura",
    },
    ClassicDice: {
      redirectUrl: "/casino/aura-classic-dice",
      imgUrl: "./images/b11/ClassicDice.webp",
      name: "Classic Dice",
      providerName: "Aura",
    },
    cointoss: {
      redirectUrl: "/casino/aura-coin-toss",
      imgUrl: "./images/b11/cointoss.webp",
      name: "coin toss",
      providerName: "Aura",
    },
    Cricket: {
      redirectUrl: "/casino/aura-cricket",
      imgUrl: "./images/b11/Cricket.webp",
      name: "Cricket",
      providerName: "Aura",
    },
    Dice: {
      redirectUrl: "/casino/spribe-dice",
      imgUrl: "./images/b11/Dice.webp",
      name: "Dice",
      providerName: "Spribe",
    },
    FortumeWheel: {
      redirectUrl: "/casino/aura-fortune-wheel",
      imgUrl: "./images/b11/FortumeWheel.webp",
      name: "Fortune Wheel",
      providerName: "Aura",
    },
    GOal: {
      redirectUrl: "/casino/spribe-goal",
      imgUrl: "./images/b11/GOal.webp",
      name: "Goal",
      providerName: "Spribe",
    },
    hilo: {
      redirectUrl: "/casino/spribe-hi-lo",
      imgUrl: "./images/b11/hilo.webp",
      name: "hi lo",
      providerName: "Spribe",
    },
    Limbo: {
      redirectUrl: "/casino/aura-limbo",
      imgUrl: "./images/b11/Limbo.webp",
      name: "Limbo",
      providerName: "Aura",
    },
    Mines: {
      redirectUrl: "/casino/spribe-mines",
      imgUrl: "./images/b11/Mines.webp",
      name: "Mines",
      providerName: "Spribe",
    },
    MinesAura: {
      redirectUrl: "/casino/aura-mines",
      imgUrl: "./images/b11/Mines-Aura.webp",
      name: "Mines Aura",
      providerName: "Aura",
    },
    miniroulette: {
      redirectUrl: "/casino/spribe-mini-roulette",
      imgUrl: "./images/b11/miniroulette.webp",
      name: "Mini roulette",
      providerName: "Spribe",
    },
    Plinko: {
      redirectUrl: "/casino/spribe-plinko",
      imgUrl: "./images/b11/Plinko.webp",
      name: "Plinko",
      providerName: "Spribe",
    },
    Rockpaperscissors: {
      redirectUrl: "/casino/aura-rock-paper-scissors",
      imgUrl: "./images/b11/Rockpaperscissors.webp",
      name: "Rock paper scissors",
      providerName: "Aura",
    },
    Slotgame: {
      redirectUrl: "/casino/aura-slot-game",
      imgUrl: "./images/b11/Slotgame.webp",
      name: "Slot game",
      providerName: "Aura",
    },
  },
  popularGames: {
    Megawheel: {
      redirectUrl: "/casino/qtechdragoonsoft-roll-in-money",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/rollinmoney.webp",
      alt: "Qtech",
      name: "Roll in Money",
      providerName: "Qtech",
    },
    BlackjackB: {
      redirectUrl: "/casino/ezgevo-blackjack-b",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/BlackjackB-Evo.webp",
      alt: "BlackjackB-Evo",
      name: "Blackjack B Evo",
      providerName: "Evolution",
    },
    Bluefortune: {
      redirectUrl: "/casino/qtechquickspin-blue-fortune",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/Bluefortune.webp",
      alt: "",
      name: "Bluefortune",
      providerName: "Qtech",
    },
    CrazyTimeEvo: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/CrazyTimeEvo.webp",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    DragonTigerEvo: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/DragonTigerEvo.webp",
      alt: "",
      name: "DragonTiger",
      providerName: "Evolution",
    },
    DreamCatcher: {
      redirectUrl: "/casino/ezgevo-dream-catcher",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/DreamCatcher.webp",
      alt: "",
      name: "Dream Catcher",
      providerName: "Evolution",
    },
    Fotunaterabbit: {
      redirectUrl: "/casino/qtechrtgslots-fortunate-rabbit",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/Fotunaterabbit.webp",
      alt: "",
      name: "Fotunate rabbit",
      providerName: "Qtech",
    },
    GemsGoneWild: {
      redirectUrl: "/casino/ezgrt-gems-gone-wild",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/GemsGoneWild.webp",
      alt: "",
      name: "Gems Gone Wild",
      providerName: "Redtiger",
    },
    hotmania: {
      redirectUrl: "/casino/qtechevoplay-hot-mania",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/hotmania.webp",
      alt: "",
      name: "hot mania",
      providerName: "Qtech",
    },
    lightningroulete: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/lightningroulete.webp",
      alt: "",
      name: "lightning roulette",
      providerName: "Evolution",
    },
    megaball: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/megaball.webp",
      alt: "",
      name: "Megaball",
      providerName: "Evolution",
    },
    speedblackjack: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-1",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/speedblackjack.webp",
      alt: "",
      name: "speed blackjack",
      providerName: "Evolution",
    },
    wanteddeadorawild: {
      redirectUrl: "/casino/qtechhacksawgaming-wanted-dead-or-a-wild",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/wanteddeadorawild.webp",
      alt: "",
      name: "wanted dead or a wild",
      providerName: "Qtech",
    },
  },

  AndarBahar: {
    up7down: {
      redirectUrl: "/casino/km-7-up-7-down",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/7up7down.webp",
      alt: "",
      name: "7 up 7 down",
      providerName: "Kingmaker",
    },
    Cards32: {
      redirectUrl: "/casino/km-32-cards",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/32Cards.webp",
      alt: "",
      name: "32 Cards",
      providerName: "Kingmaker",
    },
    Baccarat: {
      redirectUrl: "/casino/km-baccarat",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/Baccarat.webp",
      alt: "",
      name: "Baccarat",
      providerName: "Kingmaker",
    },
    baiBuu: {
      redirectUrl: "/casino/km-bai-buu",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/baiBuu.webp",
      alt: "",
      name: "Bai Buu",
      providerName: "Kingmaker",
    },
    BaiCao: {
      redirectUrl: "/casino/km-bai-cao",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/BaiCao.webp",
      alt: "",
      name: "Bai Cao",
      providerName: "Kingmaker",
    },
    BengKai2: {
      redirectUrl: "/casino/km-belangkai-2",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/BengKai2.webp",
      alt: "",
      name: "Belang kai 2",
      providerName: "Kingmaker",
    },
    Blackjack: {
      redirectUrl: "/casino/km-blackjack",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/Blackjack.webp",
      alt: "",
      name: "Blackjack",
      providerName: "Kingmaker",
    },
    Bolagolek: {
      redirectUrl: "/casino/km-bola-golek",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/Bolagolek.webp",
      alt: "",
      name: "Bolagolek",
      providerName: "Kingmaker",
    },
    BonusDice: {
      redirectUrl: "/casino/km-bonus-dice",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/BonusDice.webp",
      alt: "",
      name: "Bonus Dice",
      providerName: "Kingmaker",
    },
    Cardpoker: {
      redirectUrl: "/casino/km-5-card-poker",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/Cardpoker.webp",
      alt: "",
      name: "Card poker",
      providerName: "Kingmaker",
    },
    CardsHilo: {
      redirectUrl: "/casino/km-card-hi-lo",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/CardsHilo.webp",
      alt: "",
      name: "Cards Hilo",
      providerName: "Kingmaker",
    },
    CashRocket: {
      redirectUrl: "/casino/km-cash-rocket",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/CashRocket.webp",
      alt: "",
      name: "Cash Rocket",
      providerName: "Kingmaker",
    },
    Cleopatarstreasure: {
      redirectUrl: "/casino/km-cleopatras-treasure",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/Cleopatar'streasure.webp",
      alt: "",
      name: "Cleopatar's treasure",
      providerName: "Kingmaker",
    },
    GolaTangkas: {
      redirectUrl: "/casino/km-bola-tangkas",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/GolaTangkas.webp",
      alt: "",
      name: "Bola Tangkas",
      providerName: "Kingmaker",
    },
  },

  roulettetab: {
    AgentRoyale: {
      redirectUrl: "/casino/ezgrt-agent-royale",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/AgentRoyale.webp",
      alt: "",
      name: "Agent Royale",
      providerName: "Red Tiger",
    },
    arcadebomb: {
      redirectUrl: "/casino/ezgrt-arcade-bomb",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/arcadebomb.webp",
      alt: "arcadebomb",
      name: "arcade bomb",
      providerName: "Red Tiger",
    },
    Atlantis: {
      redirectUrl: "/casino/ezgrt-atlantis",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/Atlantis.webp",
      alt: "Atlantis",
      name: "Atlantis",
      providerName: "Red Tiger",
    },
    aztecspins: {
      redirectUrl: "/casino/ezgrt-aztec-spins",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/aztecspins.webp",
      alt: "",
      name: "aztec spins",
      providerName: "Red Tiger",
    },
    CinderellaBall: {
      redirectUrl: "/casino/ezgrt-cinderellas-ball",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/CinderellaBall.webp",
      alt: "",
      name: "Cinderella Ball",
      providerName: "Red Tiger",
    },
    CirquedelaFortune: {
      redirectUrl: "/casino/ezgrt-cirque-de-la-fortune",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/CirquedelaFortune.webp",
      alt: "",
      name: "Cirquedela Fortune",
      providerName: "Red Tiger",
    },
    ClashoftheBeasts: {
      redirectUrl: "/casino/ezgrt-clash-of-the-beasts",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/ClashoftheBeasts.webp",
      alt: "",
      name: "Clash of the Beasts",
      providerName: "Red Tiger",
    },
    CrazyGenie: {
      redirectUrl: "/casino/ezgrt-crazy-genie",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/CrazyGenie.webp",
      alt: "",
      name: "Crazy Genie",
      providerName: "Red Tiger",
    },
    CrystalMirror: {
      redirectUrl: "/casino/ezgrt-crystal-mirror",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/CrystalMirror.webp",
      alt: "",
      name: "Crystal Mirror",
      providerName: "Red Tiger",
    },
    DaVinciMystery: {
      redirectUrl: "/casino/ezgrt-da-vincis-mystery",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/DaVinciMystery.webp",
      alt: "",
      name: "DaVinci Mystery",
      providerName: "Red Tiger",
    },
    devilnumber: {
      redirectUrl: "/casino/ezgrt-devils-number",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/devilnumber.webp",
      alt: "",
      name: "devil number",
      providerName: "Red Tiger",
    },
    DiceDice: {
      redirectUrl: "/casino/ezgrt-dice-dice-dice",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/DiceDice.webp",
      alt: "",
      name: "Dice Dice",
      providerName: "Red Tiger",
    },
    families5: {
      redirectUrl: "/casino/ezgrt-5-families",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/families5.webp",
      alt: "",
      name: "families 5",
      providerName: "Red Tiger",
    },
    FiveStarpowerreel: {
      redirectUrl: "/casino/ezgrt-five-stars-power-reels",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/FiveStarpowerreel.webp",
      alt: "",
      name: "Five Star power reel",
      providerName: "Red Tiger",
    },
    GemsGoneWildPowerReels: {
      redirectUrl: "/casino/ezgrt-gems-gone-wild-power-reels",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/GemsGoneWildPowerReels.webp",
      alt: "",
      name: "Gems Gone Wild Power Reels",
      providerName: "Red Tiger",
    },
    GoldenLeprechaunMegaways: {
      redirectUrl: "/casino/ezgrt-golden-leprechaun-megaways",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/GoldenLeprechaunMegaways.webp",
      alt: "",
      name: "Golden Leprechaun Megaways",
      providerName: "Red Tiger",
    },
    HoardofPoseidon: {
      redirectUrl: "/casino/ezgrt-hoard-of-poseidon",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/HoardofPoseidon.webp",
      alt: "",
      name: "Hoard of Poseidon",
      providerName: "Red Tiger",
    },
    JewelScarabs: {
      redirectUrl: "/casino/ezgrt-jewel-scarabs",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/JewelScarabs.webp",
      alt: "",
      name: "JewelS carabs",
      providerName: "Red Tiger",
    },
    JingleBellsPowerReels: {
      redirectUrl: "/casino/ezgrt-jingle-bells-power-reels",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/JingleBellsPowerReels.webp",
      alt: "",
      name: "Jingle Bells Power Reels",
      providerName: "Red Tiger",
    },
    LegendofAthena: {
      redirectUrl: "/casino/ezgrt-legend-of-athena",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/LegendofAthena.webp",
      alt: "",
      name: "Legend of Athena",
      providerName: "Red Tiger",
    },
    LuckyHalloween: {
      redirectUrl: "/casino/ezgrt-lucky-halloween",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/LuckyHalloween.webp",
      alt: "",
      name: "Lucky Halloween",
      providerName: "Red Tiger",
    },
    wildworlds: {
      redirectUrl: "/casino/ezgne-wild-worlds",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/wildworlds.webp",
      alt: "",
      name: "wild worlds",
      providerName: "NetEnt",
    },
    WildWater: {
      redirectUrl: "/casino/ezgne-wild-water",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/WildWater.webp",
      alt: "",
      name: "Wild Water",
      providerName: "NetEnt",
    },
    warlords: {
      redirectUrl: "/casino/ezgne-warlords-crystal-of-power",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/warlords.webp",
      alt: "",
      name: "war lords",
      providerName: "NetEnt",
    },
    Victorious: {
      redirectUrl: "/casino/ezgne-victorious",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/slots/Victorious.webp",
      alt: "",
      name: "Victorious",
      providerName: "NetEnt",
    },
  },

  dragontiger: {
    cashorcrash: {
      redirectUrl: "/casino/ezgevo-cash-or-crash",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshow/cashorcrash.webp",
      alt: "cashorcrash",
      name: "cash or crash",
      providerName: "Evolution",
    },
    CrazyTimeEvo: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshow/CrazyTimeEvo.webp",
      alt: "",
      name: "Crazy Time Evo",
      providerName: "Evolution",
    },
    footballstudio: {
      redirectUrl: "/casino/ezgevo-football-studio",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshow/footballstudio.webp",
      alt: "",
      name: "football studio",
      providerName: "Evolution",
    },
    LightningDice: {
      redirectUrl: "/casino/ezgevo-lightning-dice",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshow/LightningDice.webp",
      alt: "",
      name: "Lightning Dice",
      providerName: "Evolution",
    },
    LIGHTNINGROUlette: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshow/LIGHTNINGROUlette.webp",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },
    megaball: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshow/megaball.webp",
      alt: "",
      name: "megaball",
      providerName: "Evolution",
    },
    xxxlightningroulette: {
      redirectUrl: "/casino/ezgevo-xxxtreme-lightning-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshow/xxxlightningroulette.webp",
      alt: "",
      name: "xxx lightning roulette",
      providerName: "Evolution",
    },
  },

  baccarattab: {
    Evolution: {
      redirectUrl: "/casino/evolution",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Evolution.webp",
      alt: "",
      name: "Evolution",
      providerName: "Evolution",
    },
    Ezugi: {
      redirectUrl: "/casino/ezugi",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Ezugi.webp",
      alt: "",
      name: "Ezugi",
      providerName: "Ezugi",
    },
    Onlyplay: {
      redirectUrl: "/casino/onlyplay",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Onlyplay.webp",
      alt: "",
      name: "Onlyplay",
      providerName: "Onlyplay",
    },
    worldcasino: {
      redirectUrl: "/casino/worldcasino",
      code: "",
      casino: "wco",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Worldcasino.webp",
      alt: "",
      name: "World Casino",
      providerName: "World Casino",
    },
    Qtech: {
      redirectUrl: "/casino/qtech",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Qtech.webp",
      alt: "",
      name: "Qtech",
      providerName: "Qtech",
    },
    AESexy: {
      redirectUrl: "/casino/aesexy",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/AESexy.webp",
      alt: "",
      name: "AE Sexy",
      providerName: "AE Sexy",
    },
    Supernowa: {
      redirectUrl: "/casino/supernowa",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Supernowa.webp",
      alt: "",
      name: "Supernowa",
      providerName: "Supernowa",
    },
    Vivogaming: {
      redirectUrl: "/casino/vivo",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Vivogaming.webp",
      alt: "",
      name: "Vivo gaming",
      providerName: "Vivogaming",
    },
    Xpg: {
      redirectUrl: "/casino/xpg",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Xpg.webp",
      alt: "",
      name: "Xpg",
      providerName: "Xpg",
    },
  },
  livecasino: {
    Hand2CasinoHoldem: {
      redirectUrl: "/casino/ezgevo-2-hand-casino-holdem",
      imgUrl: "./images/livecasino/new/2handcasinoholdem.webp",
      name: "2 Hand Casino Holdem",
      providerName: "Evolution",
    },
    SupernowaAndaBahar: {
      redirectUrl: "/casino/sn-andar-bahar",
      imgUrl: "./images/livecasino/new/Andarbahar.webp",
      name: "Andar Bahar",
      providerName: "Supernowa",
    },
    EzugiAndaBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      imgUrl: "./images/livecasino/new/AndarbaharEZ.webp",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    WCAndaBahar: {
      redirectUrl: "/casino/ez-andar-bahar",
      imgUrl: "./images/livecasino/new/AndarBahar-WC.webp",
      name: "Andar Bahar",
      providerName: "World Casino",
    },
    AutoRouletteEzugi: {
      redirectUrl: "/casino/ezg-auto-roulette",
      imgUrl: "./images/livecasino/new/AutoRouletteEZ.webp",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    WCAndaBahar: {
      redirectUrl: "/casino/ezgevo-baccarat-a",
      imgUrl: "./images/livecasino/new/baccarat.webp",
      name: "Baccarat",
      providerName: "Ezugi",
    },
    BaccaratAEvolution: {
      redirectUrl: "/casino/ezgevo-baccarat-a",
      imgUrl: "./images/livecasino/new/BaccaratAEvo.webp",
      name: "Baccarat A",
      providerName: "Evolution",
    },
    BaccaratKnouckoutExugi: {
      redirectUrl: "/casino/ezg-knockout-baccarat",
      imgUrl: "./images/livecasino/new/knockoutbaccarat.webp",
      name: "Knockout Baccarat",
      providerName: "Ezugi",
    },
    BetOnTeenPatti: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      imgUrl: "./images/livecasino/new/Betonteenpatti.webp",
      name: "Bet On Teen Patti",
      providerName: "Ezugi",
    },
    BlackjackA: {
      redirectUrl: "/casino/ezgevo-blackjack-a",
      imgUrl: "./images/livecasino/new/BlackjackAEvo.webp",
      name: "Blackjack A",
      providerName: "Evolution",
    },
    CasinoMarinaBaccarat1: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-1",
      imgUrl: "./images/livecasino/new/CasinoMarinaBaccarat1.webp",
      name: "Casino Marina Baccarat 1",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat2: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-2",
      imgUrl: "./images/livecasino/new/CasinoMarinaBaccarat2.webp",
      name: "Casino Marina Baccarat 2",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat3: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-3",
      imgUrl: "./images/livecasino/new/CasinoMarinaBaccarat3.webp",
      name: "Casino Marina Baccarat 3",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat4: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-4",
      imgUrl: "./images/livecasino/new/CasinoMarinaBaccarat4.webp",
      name: "Casino Marina Baccarat 4",
      providerName: "Ezugi",
    },
    ClassicAndarBahar: {
      redirectUrl: "/casino/sn-classic-andar-bahar",
      imgUrl: "./images/livecasino/new/ClassicAndarBahar.webp",
      name: "Classic Andar Bahar",
      providerName: "Supernowa",
    },
    CrazyTimeEvo: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      imgUrl: "./images/livecasino/new/CrazyTimeEvo.webp",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    CricketWar: {
      redirectUrl: "/casino/ezg-cricket-war",
      imgUrl: "./images/livecasino/new/CricketWar.webp",
      name: "Cricket War",
      providerName: "Evolution",
    },
    DiamondRouletteEZ: {
      redirectUrl: "/casino/ezg-diamond-roulette",
      imgUrl: "./images/livecasino/new/DiamondRouletteEZ.webp",
      name: "Diamond Roulette",
      providerName: "Ezugi",
    },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      imgUrl: "./images/livecasino/new/dragontiger.webp",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    DragonTigerEvo: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      imgUrl: "./images/livecasino/new/DragonTigerEvo.webp",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
    BaccaratControlSqueeze: {
      redirectUrl: "/casino/ezgevo-baccarat-control-squeeze",
      imgUrl: "./images/livecasino/new/FiestaBaccarat.webp",
      name: "Baccarat Control Squeeze",
      providerName: "Ezugi",
    },
    FirstPersonDragonTiger: {
      redirectUrl: "/casino/ezgevo-first-person-dragon-tiger",
      imgUrl: "./images/livecasino/new/FirstPersonDragonTiger.webp",
      name: "First Person Dragon Tiger",
      providerName: "Evolution",
    },
    FortuneBaccarat: {
      redirectUrl: "/casino/	ezg-fortune-baccarat",
      imgUrl: "./images/livecasino/new/FortuneBaccarat.webp",
      name: "Fortune Baccarat",
      providerName: "Ezugi",
    },
    GoasAndarBahar: {
      redirectUrl: "/casino/sn-goas-andar-bahar",
      imgUrl: "./images/livecasino/new/GoasAndarBahar.webp",
      name: "Goa's Andar Baha",
      providerName: "Supernowa",
    },
    GoldBlackjack1: {
      redirectUrl: "/casino/ezg-gold-blackjack-1",
      imgUrl: "./images/livecasino/new/GoldBlackjack1.webp",
      name: "Gold Blackjack 1",
      providerName: "Ezugi",
    },
    Goldblackjack3: {
      redirectUrl: "/casino/ezg-gold-blackjack-3",
      imgUrl: "./images/livecasino/new/Goldblackjack3.webp",
      name: "Gold Blackjack 3",
      providerName: "Ezugi",
    },
    Goldblackjack5: {
      redirectUrl: "/casino/ezg-gold-blackjack-5",
      imgUrl: "./images/livecasino/new/GoldBlackjack5.webp",
      name: "Gold Blackjack 5",
      providerName: "Ezugi",
    },
    InstantRouletteEvo: {
      redirectUrl: "/casino/ezgevo-instant-roulette",
      imgUrl: "./images/livecasino/new/InstantRouletteEvo.webp",
      name: "Instant Roulette",
      providerName: "Evolution",
    },
    ItalianRouletteEZ: {
      redirectUrl: "/casino/ezg-italian-roulette",
      imgUrl: "./images/livecasino/new/ItalianRouletteEZ.webp",
      name: "Italian Roulette",
      providerName: "Ezugi",
    },
    ItalianRouletteEZ: {
      redirectUrl: "/casino/ezg-italian-roulette",
      imgUrl: "./images/livecasino/new/ItalianRouletteEZ.webp",
      name: "Italian Roulette",
      providerName: "Ezugi",
    },
    Lasvegasblackjack: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      imgUrl: "./images/livecasino/new/Lasvegasblackjack.webp",
      name: "Las Vegas Blackjack",
      providerName: "Vivo",
    },
    Lucky7: {
      redirectUrl: "/casino/ezg-lucky-7",
      imgUrl: "./images/livecasino/new/Lucky7.webp",
      name: "Lucky 7",
      providerName: "Ezugi",
    },
    megaball: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      imgUrl: "./images/livecasino/new/megaball.webp",
      name: "Mega Ball",
      providerName: "Evolution",
    },
    NamasteRouletteEZ: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      imgUrl: "./images/livecasino/new/NamasteRouletteEZ.webp",
      name: "Namaste Roulette",
      providerName: "Ezugi",
    },
    NoCommissionBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      imgUrl: "./images/livecasino/new/NoCommissionBaccarat.webp",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
    },
    OracleRoulette2EZ: {
      redirectUrl: "/casino/ezg-oracle-roulette-2",
      imgUrl: "./images/livecasino/new/OracleRoulette2EZ.webp",
      name: "Oracle Roulette 2",
      providerName: "Ezugi",
    },
    OttAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
      imgUrl: "./images/livecasino/new/OttAndarBahar.webp",
      name: "OTT Andar Bahar",
      providerName: "Ezugi",
    },

    RNGCasinoQueen: {
      redirectUrl: "/casino/sn-rng-casino-queen",
      imgUrl: "./images/livecasino/new/RNGCasinoQueen.webp",
      name: "RNG Casino Queen",
      providerName: "Supernowa",
    },
    RNGcasinoqueen2020: {
      redirectUrl: "/casino/sn-rng-casino-queen-2020",
      imgUrl: "./images/livecasino/new/RNGcasinoqueen2020.webp",
      name: "RNG Casino Queen 2020",
      providerName: "Supernowa",
    },

    RNGDragonTiger: {
      redirectUrl: "/casino/sn-rng-dragon-tiger",
      imgUrl: "./images/livecasino/new/RNGDragonTiger.webp",
      name: "RNG Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTiger2020: {
      redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
      imgUrl: "./images/livecasino/new/RNGDragonTiger2020.webp",
      name: "RNG Dragon Tiger 2020",
      providerName: "Supernowa",
    },
    RNGJoker: {
      redirectUrl: "/casino/sn-rng-joker",
      imgUrl: "./images/livecasino/new/RNGJoker.webp",
      name: "RNG Joker",
      providerName: "Supernowa",
    },
    RNGKingRace: {
      redirectUrl: "/casino/sn-rng-king-race",
      imgUrl: "./images/livecasino/new/RNGKingRace.webp",
      name: "RNG King Race",
      providerName: "Supernowa",
    },
    RNGLucky7: {
      redirectUrl: "/casino/sn-rng-lucky-7",
      imgUrl: "./images/livecasino/new/RNGLucky7.webp",
      name: "RNG Lucky 7",
      providerName: "Supernowa",
    },
    RNGTeenpatti: {
      redirectUrl: "/casino/sn-rng-teen-patti",
      imgUrl: "./images/livecasino/new/RNGTeenpatti.webp",
      name: "RNG Teen Patti",
      providerName: "Supernowa",
    },
    RNGTeenpatti2020: {
      redirectUrl: "/casino/sn-rng-teen-patti-2020",
      imgUrl: "./images/livecasino/new/RNGTeenpatti2020.webp",
      name: "RNG Teen Patti 2020",
      providerName: "Supernowa",
    },
    RNGWorlimatka: {
      redirectUrl: "/casino/sn-rng-worli-matka",
      imgUrl: "./images/livecasino/new/RNGWorlimatka.webp",
      name: "RNG Worli Matka",
      providerName: "Supernowa",
    },
    BlackjackParty: {
      redirectUrl: "/casino/ezgevo-blackjack-party",
      imgUrl: "./images/livecasino/new/BlackjackParty-Evo.webp",
      name: "Blackjack Party",
      providerName: "Ezugi",
    },
    BlackjackC: {
      redirectUrl: "/casino/ezgevo-blackjack-c",
      imgUrl: "./images/livecasino/new/blackjackc-Evo.webp",
      name: "Blackjack C",
      providerName: "Ezugi",
    },
    BlackjackSilverA: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-a",
      imgUrl: "./images/livecasino/new/blackjacksilvera-Evo.webp",
      name: "Blackjack Silver A",
      providerName: "Ezugi",
    },
    BlackjackSilverE: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-e",
      imgUrl: "./images/livecasino/new/blackjacksilvere-Evo.webp",
      name: "Blackjack Silver E",
      providerName: "Evolution",
    },
    SpanishRouletteEZ: {
      redirectUrl: "/casino/ezg-spanish-roulette",
      imgUrl: "./images/livecasino/new/SpanishRouletteEZ.webp",
      name: "Spanish Roulette",
      providerName: "Ezugi",
    },
    SpeedAutoRouletteEZ: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      imgUrl: "./images/livecasino/new/SpeedAutoRouletteEZ.webp",
      name: "Speed Auto Roulette",
      providerName: "Ezugi",
    },

    SpeedRouletteEZ: {
      redirectUrl: "/casino/ezg-speed-roulette",
      imgUrl: "./images/livecasino/new/SpeedRouletteEZ.webp",
      name: "Speed Roulette",
      providerName: "Ezugi",
    },
    Super6Baccarat: {
      redirectUrl: "/casino/ezg-super-6-baccarat",
      imgUrl: "./images/livecasino/new/Super6Baccarat.webp",
      name: "Super 6 Baccarat",
      providerName: "Ezugi",
    },
    SuperSicBo: {
      redirectUrl: "/casino/ezgevo-super-sic-bo",
      imgUrl: "./images/livecasino/new/SuperSicBoEvo.webp",
      name: "Super Sic Bo",
      providerName: "Ezugi",
    },
    teenpatti3card: {
      redirectUrl: "/casino/ezg-teen-patti-3-card",
      imgUrl: "./images/livecasino/new/teenpatti3card.webp",
      name: "Teen Patti 3 Card",
      providerName: "Ezugi",
    },
    texasholdembonus: {
      redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
      imgUrl: "./images/livecasino/new/texasholdembonus.webp",
      name: "Texas Hold'em Bonus Poker",
      providerName: "Evolution",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      imgUrl: "./images/livecasino/new/UltimateAndarBahar.webp",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    UltimateRouletteEZ: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      imgUrl: "./images/livecasino/new/UltimateRouletteEZ.webp",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    Ultimatetexasholdem: {
      redirectUrl: "/casino/ezgevo-ultimate-texas-holdem",
      imgUrl: "./images/livecasino/new/Ultimatetexasholdem.webp",
      name: "Ultimate Texas Hold'em	",
      providerName: "Evolution",
    },
    VABaccarart1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      imgUrl: "./images/livecasino/new/VaBaccarat1.webp",
      name: "VA Baccarat 1",
      providerName: "Vivo",
    },
    VABaccarart2: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      imgUrl: "./images/livecasino/new/VaBaccarat2.webp",
      name: "VA Baccarat 2",
      providerName: "Vivo",
    },
    VABaccarart3: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      imgUrl: "./images/livecasino/new/VaBaccarat3.webp",
      name: "VA Baccarat 3",
      providerName: "Vivo",
    },
    VABaccarart4: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      imgUrl: "./images/livecasino/new/VaBaccarat4.webp",
      name: "VA Baccarat 4",
      providerName: "Vivo",
    },
    VABaccarart5: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      imgUrl: "./images/livecasino/new/VaBaccarat5.webp",
      name: "VA Baccarat 5",
      providerName: "Vivo",
    },
    VIPFortuneBaccarat: {
      redirectUrl: "/casino/ezg-vip-fortune-baccarat",
      imgUrl: "./images/livecasino/new/VIPFortuneBaccarat.webp",
      name: "VIP Fortune Baccarat",
      providerName: "Ezugi",
    },
    VivoAndarBahar: {
      redirectUrl: "/casino/vivo-andar-bahar",
      imgUrl: "./images/livecasino/new/Vivo-Andarbahar.webp",
      name: "Andar Bahar",
      providerName: "Vivo",
    },
    vivoDragonTiger: {
      redirectUrl: "/casino/vivo-dragon-tiger",
      imgUrl: "./images/livecasino/new/vivoDragonTiger.webp",
      name: "Dragon Tiger",
      providerName: "Vivo",
    },
    vivolasvegasroulette: {
      redirectUrl: "/casino/vivo-las-vegas-roulette",
      imgUrl: "./images/livecasino/new/vivolasvegasroulette.webp",
      name: "Las Vegas Roulette",
      providerName: "Vivo",
    },
    vivomacaubaccarat: {
      redirectUrl: "/casino/vivo-macau-baccarat",
      imgUrl: "./images/livecasino/new/vivomacaubaccarat.webp",
      name: "Macau Baccarat",
      providerName: "Vivo",
    },
    vivoprotomasoroulette: {
      redirectUrl: "/casino/vivo-portomaso-roulette",
      imgUrl: "./images/livecasino/new/vivoprotomasoroulette.webp",
      name: "Portomaso Roulette",
      providerName: "Vivo",
    },
    Vivoteenpatti: {
      redirectUrl: "/casino/vivo-teen-patti",
      imgUrl: "./images/livecasino/new/Vivo-teenpatti.webp",
      name: "Teen Patti",
      providerName: "Vivo",
    },
  },
  tablegame: {
    Hand2CasinoHoldem: {
      redirectUrl: "/casino/ezgevo-2-hand-casino-holdem",
      imgUrl: "./images/livecasino/new/2handcasinoholdem.webp",
      name: "2 Hand Casino Holdem",
      providerName: "Evolution",
    },
    Americanroulette: {
      redirectUrl: "/casino/ezgevo-american-roulette",
      imgUrl: "./images/tablegame/Americanroulette.webp",
      name: "American Roulette",
      providerName: "Evolution",
    },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      imgUrl: "./images/livecasino/new/dragontiger.webp",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    DragonTigerEvo: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      imgUrl: "./images/livecasino/new/DragonTigerEvo.webp",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
    FirstPersonRoulette: {
      redirectUrl: "/casino/ezgevo-first-person-roulette",
      imgUrl: "./images/tablegame/FirstPersonRoulette.webp",
      name: "First Person Roulette",
      providerName: "Evolution",
    },
    ItalianRouletteEZ: {
      redirectUrl: "/casino/ezg-italian-roulette",
      imgUrl: "./images/livecasino/new/ItalianRouletteEZ.webp",
      name: "Italian Roulette",
      providerName: "Ezugi",
    },
    Sicbo: {
      redirectUrl: "/casino/ezg-sic-bo",
      imgUrl: "./images/tablegame/sicbo.webp",
      name: "Sic Bo",
      providerName: "Ezugi",
    },
  },
  tvShows: {
    Blackjack: {
      redirectUrl: "/casino/ezg-blackjack-1",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack.jpg",
      alt: "",
      name: "Blackjack",
      providerName: "Ezugi",
    },
    LightningDice: {
      redirectUrl: "/casino/ezgevo-lightning-dice",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/lightningdice.jpg",
      alt: "",
      name: "Lightning Dice",
      providerName: "Evolution",
    },
    CrazyTime: {
      redirectUrl: "/casino/ezugi/CrazyTime",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/CrazyTime.jpg",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    Megaball: {
      redirectUrl: "/casino/ezugi/Megaball",
      code: "1000141",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Megaball.jpg",
      alt: "",
      name: "Megaball",
      providerName: "Evolution",
    },
  },

  blackjacktab: {
    LasVegasBlackjackVivo: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Lasvegasblackjack.webp",
      alt: "vivo las vegas blackjack",
      name: "Vivo Las Vegas Blackjack",
      providerName: "Vivo",
    },
    BlackjackGold3: {
      redirectUrl: "/casino/ez-blackjack-gold-3",
      code: "203",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Goldblackjack3.webp",
      alt: "",
      name: " Blackjack Gold 3  ",
      providerName: "Ezugi",
    },
    BlackjackGold4: {
      redirectUrl: "/casino/ezg-gold-blackjack-4",
      code: "203",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Goldblackjack3.webp",
      alt: "",
      name: "Gold Blackjack 4",
      providerName: "Ezugi",
    },
    BlackjackGold5: {
      redirectUrl: "/casino/ezg-gold-blackjack-5",
      code: "203",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Goldblackjack3.webp",
      alt: "",
      name: "Gold Blackjack 5",
      providerName: "Ezugi",
    },

    GoldBlackjack1: {
      redirectUrl: "/casino/ezg-gold-blackjack-1",
      code: "203",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Goldblackjack3.webp",
      alt: "",
      name: "Gold Blackjack 1",
      providerName: "Ezugi",
    },
    GoldBlackjack6: {
      redirectUrl: "/casino/ezg-gold-blackjack-6",
      code: "203",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Goldblackjack3.webp",
      alt: "",
      name: "Gold Blackjack 6",
      providerName: "Ezugi",
    },
    RussianBlackjack1: {
      redirectUrl: "/casino/ezg-russian-blackjack-1",
      code: "203",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Goldblackjack3.webp",
      alt: "",
      name: "Russian Blackjack 1",
      providerName: "Ezugi",
    },
    RussianBlackjack2: {
      redirectUrl: "/casino/ezg-russian-blackjack-2",
      code: "203",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Goldblackjack3.webp",
      alt: "",
      name: "Russian Blackjack 2",
      providerName: "Ezugi",
    },
    SalonPriveBlackjack: {
      redirectUrl: "/casino/ezg-salon-prive-blackjack",
      code: "203",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Goldblackjack3.webp",
      alt: "",
      name: "Salon Prive Blackjack",
      providerName: "Ezugi",
    },
  },

  pokertab: {
    Hand2CasinoHoldem: {
      redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
      code: "1000073",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/2handcasinoholdem.webp",
      alt: "",
      name: " 2 Hand Casino Holdem",
      providerName: "Evolution",
    },
    BetonTeenPatti: {
      redirectUrl: "/casino/ezugi/BetonTeenPatti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Betonteenpatti.webp",
      alt: "",
      name: "Bet on Teen Patti",
      providerName: "Ezugi",
    },
    SideBetCity: {
      redirectUrl: "/casino/ezugi/SideBetCity",
      code: "1000115",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Sidebetcity.jpg",
      alt: "",
      name: "Side Bet City",
      providerName: "Evolution",
    },
    TeenPatti3card: {
      redirectUrl: "/casino/ezugi/TeenPatti3card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/teenpatti3card.webp",
      alt: "",
      name: " Teen Patti 3 Card",
      providerName: "Ezugi",
    },
    TexasHoldembonus: {
      redirectUrl: "/casino/ezugi/TexasHoldembonus",
      code: "1000111",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/texasholdembonus.webp",
      alt: "",
      name: "Texas Holdem Bonus",
      providerName: "Evolution",
    },
    Ultimatetexasholdem: {
      redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
      code: "1000151",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/ultimatetaxasholden.jpg",
      alt: "",
      name: "Ultimate Texas Holdem",
      providerName: "Evolution",
    },
  },

  spribe: {
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/new/Aviator.webp",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    mines: {
      redirectUrl: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/new/Mines.webp",
      alt: "",
      name: "Mines",
      providerName: "Spribe",
    },
    goal: {
      redirectUrl: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/new/Goal.webp",
      alt: "",
      name: "Goal",
      providerName: "Spribe",
    },
    dice: {
      redirectUrl: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/new/Dice.webp",
      alt: "",
      name: "Dice",
      providerName: "Spribe",
    },
    plinko: {
      redirectUrl: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/new/Plinko.webp",
      alt: "",
      name: "Plinko",
      providerName: "Spribe",
    },
    miniroulette: {
      redirectUrl: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/new/miniroulette.webp",
      alt: "",
      name: "Mini Roulette",
      providerName: "Spribe",
    },
    hilo: {
      redirectUrl: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/new/hilo.webp",
      alt: "",
      name: "Hilo",
      providerName: "Spribe",
    },
  },

  supernowa: {
    RNGCasinoQueen: {
      redirectUrl: "/casino/sn-rng-casino-queen",
      code: "RCQ",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen.webp",
      alt: "",
      name: "RNG Casino Queen",
      providerName: "Supernowa",
    },
    RNGCasinoQueen2020: {
      redirectUrl: "/casino/sn-rng-casino-queen-2020",
      code: "RCQ20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGcasinoqueen2020.webp",
      alt: "",
      name: "RNG Casino Queen 2020",
      providerName: "Supernowa",
    },

    RNGDragonTiger: {
      redirectUrl: "/casino/sn-rng-dragon-tiger",
      code: "VDT",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGDragonTiger.webp",
      alt: "",
      name: "RNG Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTiger2020: {
      redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
      code: "VDT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGDragonTiger2020.webp",
      alt: "",
      name: "RNG Dragon Tiger 2020",
      providerName: "Supernowa",
    },
    RNGJoker: {
      redirectUrl: "/casino/sn-rng-joker",
      code: "VJKR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGJoker.webp",
      alt: "",
      name: "RNG Joker",
      providerName: "Supernowa",
    },
    RNGKingRace: {
      redirectUrl: "/casino/sn-rng-king-race",
      code: "VCR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGKingRace.webp",
      alt: "",
      name: "RNG King Race",
      providerName: "Supernowa",
    },
    RNGLucky7: {
      redirectUrl: "/casino/sn-rng-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGLucky7.webp",
      alt: "",
      name: "RNG Lucky 7",
      providerName: "Supernowa",
    },
    RNGTeenPatti: {
      redirectUrl: "/casino/sn-rng-teen-patti",
      code: "VTP",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenpatti.webp",
      alt: "",
      name: "RNG Teen Patti",
      providerName: "Supernowa",
    },
    RNGTeenPatti2020: {
      redirectUrl: "/casino/sn-rng-teen-patti-2020",
      code: "VTP20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenpatti2020.webp",
      alt: "",
      name: "RNG Teen Patti 2020",
      providerName: "Supernowa",
    },
    RNGWorliMatka: {
      redirectUrl: "/casino/sn-rng-worli-matka",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGWorlimatka.webp",
      alt: "",
      name: "RNG Worli Matka",
      providerName: "Supernowa",
    },
  },

  vivo: {
    VABacarat1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      imgUrl: "./images/Vivo/VaBaccarat1.webp",
      name: "VA Bacarat 1",
      providerName: "Vivo",
    },
    VABacarat2: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      imgUrl: "./images/Vivo/VaBaccarat2.webp",
      name: "VA Bacarat 2",
      providerName: "Vivo",
    },
    VABacarat3: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      imgUrl: "./images/Vivo/VaBaccarat3.webp",
      name: "VA Bacarat 3",
      providerName: "Vivo",
    },
    VABacarat4: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      imgUrl: "./images/Vivo/VaBaccarat4.webp",
      name: "VA Bacarat 4",
      providerName: "Vivo",
    },
    VABacarat5: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      imgUrl: "./images/Vivo/VaBaccarat5.webp",
      name: "VA Bacarat 5",
      providerName: "Vivo",
    },
    LasVegasBlackjackVivo: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Lasvegasblackjack.webp",
      alt: "vivo las vegas blackjack",
      name: "Vivo Las Vegas Blackjack",
      providerName: "Vivo",
    },
    MacauBaccaratVivo: {
      redirectUrl: "/casino/vivo-macau-baccarat",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/vivomacaubaccarat.webp",
      alt: "vivo macau baccarat",
      name: "Vivo Macau Baccarat",
      providerName: "Vivo",
    },
  },
};
