import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainSlider from "../../containers/MainSlider";
import TableGameIcon from "../../assets/images/categories/TableGame.svg";
import ProviderIcon from "../../assets/images/categories/HotGames.svg";
import GameShowsIcon from "../../assets/images/categories/GameSHow.svg";
import LiveCasinoIcon from "../../assets/images/categories/LiveCasino.svg";
import SlotsIcon from "../../assets/images/categories/slots.svg";
import NewRelease from "../../assets/images/categories/NewRelease.svg";
import Toppicks from "../../assets/images/categories/TopPicks.svg";
import OriginalICon from "../../assets/images/categories/B11Original.svg";
import AllgamesCat from "../../assets/images/categories/Lobby.svg";
import SicboCat from "../../assets/images/categories/Sicbo.png";
import Cards32Cat from "../../assets/images/categories/Cards32.png";
import Lucky7Cat from "../../assets/images/categories/Lucky7.png";
import { Dropdown } from "react-bootstrap";
import SpeedRoulette from "../../assets/images/roulette/home/speed-roulette1.jpg";
import play from "../../assets/images/play-button.png";
import { casinoSection as casinoData } from "../../lib/data/casinoSection.data";
import { auraGames } from "../../lib/data/auraGames.data";
import { getNotifications } from "../../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import LeftBarSports from "./LeftBarSports";
import CategorySlider from "./CategorySlider";
import Banner_1 from "../../../src/assets/images/banner/Banner_1.webp";
import Banner_2 from "../../../src/assets/images/banner/Banner_2.webp";
import Mobile_Banner_1 from "../../../src/assets/images/banner/Mobile_Banner_1.webp";
import Mobile_Banner_2 from "../../../src/assets/images/banner/Mobile_Banner_2.webp";
import AfterLogin1 from "../../assets/images/banner/AfterLogin1.webp";
import AfterLogin2 from "../../assets/images/banner/AfterLogin2.webp";
import AfterLogin3 from "../../assets/images/banner/AfterLogin3.webp";
import AfterLogin4 from "../../assets/images/banner/AfterLogin4.webp";
const CasinoGamesSection = () => {
  const casinoSection = { ...casinoData, ...auraGames };
  const { isAuth } = useSelector((state) => state.auth);
  // const { banners } = useSelector((state) => state.app);
  const [showLobby, setShowLobby] = useState(true);
  const [selectedProvider, setSelectedProvider] = useState({
    id: 1,
    name: "All",
    eventKey: "all",
  });
  const [activeTab, setActiveTab] = useState(null);
  const [customActiveTab, setCustomActiveTab] = useState(null);
  const [allGamesData, setAllGamesData] = useState(null);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);

  const providersArray = [
    { id: 1, name: "All", eventKey: "all" },
    { id: 2, name: "Evolution", eventKey: "evolution" },
    { id: 3, name: "Ezugi", eventKey: "ezugi" },
    { id: 4, name: "Vivo", eventKey: "vivo" },
    { id: 5, name: "Aura", eventKey: "aura" },
  ];
  const tabsArray = [
    {
      id: 0,
      name: "B11 Orignal",
      key: "b11",
      imgSrc: OriginalICon,
    },
    {
      id: 1,
      name: "Top Picks",
      key: "popularGames",
      imgSrc: Toppicks,
    },
    {
      id: 2,
      name: "New Releases",
      key: "AndarBahar",
      imgSrc: NewRelease,
    },
    {
      id: 3,
      name: "Slots",
      key: "roulettetab",
      imgSrc: SlotsIcon,
    },
    {
      id: 4,
      name: "Game Shows",
      key: "dragontiger",
      imgSrc: GameShowsIcon,
    },
    {
      id: 5,
      name: "Providers",
      key: "baccarattab",
      imgSrc: ProviderIcon,
    },
    // {
    //   id: 6,
    //   name: "Tv Shows",
    //   key: "tvShows",
    //   imgSrc: TVShowsCat,
    // },
    {
      id: 7,
      name: "Live Casino",
      key: "livecasino",
      imgSrc: LiveCasinoIcon,
    },
    {
      id: 8,
      name: "Table Game",
      key: "tablegame",
      imgSrc: TableGameIcon,
    },
  ];
  const banners = [
    {
      id: 1,
      desktopBanner: Banner_1,
      mobileBanner: Mobile_Banner_1,

      url: "",
    },
    {
      id: 2,
      desktopBanner: Banner_2,
      mobileBanner: Mobile_Banner_2,

      url: "",
    },
  ];
  const afterLoginBanner = [
    {
      id: 1,

      afterLoginBanner: AfterLogin1,
      url: "",
    },
    {
      id: 2,

      afterLoginBanner: AfterLogin2,

      url: "",
    },
    {
      id: 3,

      afterLoginBanner: AfterLogin3,

      url: "",
    },
    {
      id: 4,

      afterLoginBanner: AfterLogin4,

      url: "",
    },
  ];
  useEffect(() => {
    filterAllGamesData();

    loadNotifications();
  }, []);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  const manageActiveTab = (provider) => {
    // let filteredTabs = [];
    // for (let key in casinoSection) {
    //   Object.values(casinoSection[key]).map((values) => {
    //     if (
    //       typeof values !== "string" &&
    //       values?.providerName?.toLowerCase() === provider.eventKey
    //     ) {
    //       if (!filteredTabs.some((e) => e === key)) {
    //         filteredTabs.push(key);
    //       }
    //     }
    //   });
    // }
    // let tabToBeActive = "";
    // for (let i = 0; i < filteredTabs?.length; i++) {
    //   const filterValue = tabsArray.find((f) => f.key === filteredTabs[i]);
    //   if (filterValue) {
    //     tabToBeActive = filterValue;
    //     break;
    //   }
    // }
    // setCustomActiveTab(tabToBeActive);
    // setCustomActiveTab({
    //   id: 1,
    //   name: "All Games",
    //   key: "allGames",
    //   imgSrc: RouletteCat,
    // });
    // filterAllGamesData(provider.eventKey);
  };

  const getTabActiveClass = (tab) => {
    if (customActiveTab) {
      return tab.key === customActiveTab.key;
    }
    return tab.key === activeTab?.key;
  };
  const filterAllGamesData = (provider = selectedProvider?.eventKey) => {
    let emptyArray = {};
    Object.keys(casinoSection).map((key) => {
      // console.log("key", key);
      if (
        key !== "allGames" &&
        tabsArray.some((s) => s.key === key) &&
        typeof casinoSection[key] !== "string"
      ) {
        emptyArray = { ...emptyArray, ...casinoSection[key] };
      }
    });
    let filteredValues;
    if (provider !== "all") {
      filteredValues = Object.values(emptyArray).filter(
        (f) => f.providerName?.toLowerCase() === provider
      );
    } else {
      filteredValues = Object.values(emptyArray).filter(
        (f) =>
          f.providerName?.toLowerCase() === "evolution" ||
          f.providerName?.toLowerCase() === "ezugi" ||
          f.providerName?.toLowerCase() === "vivo" ||
          f.providerName?.toLowerCase() === "aura"
      );
    }

    setAllGamesData(filteredValues);
  };
  return (
    <>
      <main className="main">
        <div className="home-section">
          <div className="left-section">
            <LeftBarSports />
          </div>

          <div className="right-section">
            <div>
              <Container fluid>
                <div className="top_banner_sec my-3">
                  <Row>
                    <Col lg={12}>
                      <div className="mainBanner CasinoPage">
                        <MainSlider
                          banners={banners}
                          afterLoginBanner={afterLoginBanner}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
            <div className="casino-games">
              <div className="game-filter">
                <ul className="categories">
                  <li
                    onClick={() => {
                      setShowLobby(true);
                      setActiveTab(null);
                    }}
                  >
                    {" "}
                    <a
                      // href="javascript:void(0)"
                      className={showLobby ? "active" : ""}
                    >
                      <img src={AllgamesCat} alt="roulette" />
                      <span>Lobby</span>
                    </a>
                  </li>
                  {tabsArray?.map((tab, index) => {
                    return (
                      <React.Fragment key={index}>
                        {((casinoSection?.[tab?.key] &&
                          Object.values(casinoSection?.[tab?.key]).some(
                            (s) =>
                              s?.providerName?.toLowerCase() ===
                              selectedProvider?.eventKey
                          )) ||
                          selectedProvider?.eventKey === "all" ||
                          tab?.key == "GamesAll") && (
                          <li
                            onClick={() => {
                              setCustomActiveTab(null);
                              setShowLobby(false);
                              if (tab.key === "allGames") {
                                filterAllGamesData();
                              }
                              setActiveTab(tab);
                            }}
                            // key={index}
                          >
                            <a
                              // href="javascript:void(0)"
                              className={getTabActiveClass(tab) ? "active" : ""}
                            >
                              <img src={tab?.imgSrc} alt="roulette" />
                              <span>{tab?.name}</span>
                            </a>
                          </li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>
                {activeTab?.key === "livecasino" && (
                  <div className="providers">
                    <Dropdown
                      onSelect={(e) => {
                        const provider =
                          providersArray.find((p) => p?.eventKey === e) ||
                          "all";
                        setSelectedProvider(provider);
                        // manageActiveTab(provider);
                      }}
                    >
                      <Dropdown.Toggle
                        variant="success"
                        id="provider-dropdown"
                        value={selectedProvider}
                      >
                        {selectedProvider?.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu value={selectedProvider}>
                        {providersArray.map((p, index) => {
                          return (
                            <Dropdown.Item key={index} eventKey={p?.eventKey}>
                              {p?.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>

              <div className="all-images">
                {showLobby ? (
                  <CategorySlider />
                ) : (
                  <ul>
                    {casinoSection?.[activeTab?.key] &&
                    activeTab?.key !== "allGames" &&
                    customActiveTab?.key !== "allGames"
                      ? Object.values(casinoSection?.[activeTab?.key]).map(
                          (casino, index) => {
                            return (
                              <React.Fragment key={index}>
                                {(selectedProvider?.eventKey === "all" ||
                                  casino?.providerName?.toLowerCase() ===
                                    selectedProvider.eventKey) && (
                                  <li key={index}>
                                    <a href={casino?.redirectUrl}>
                                      <img src={casino?.imgUrl} />
                                      <img src={play} className="play_button" />
                                      <p>{casino?.name}</p>
                                      <p className="hover_Name">
                                        {casino?.name}
                                      </p>
                                    </a>
                                    <span className="focus-border">
                                      <i></i>
                                    </span>
                                  </li>
                                )}
                              </React.Fragment>
                            );
                          }
                        )
                      : allGamesData &&
                        allGamesData.map((casino, index) => {
                          return (
                            <li key={index}>
                              <a href={casino?.redirectUrl}>
                                <img src={casino?.imgUrl} />
                                <img src={play} className="play_button" />
                                <p>{casino?.name}</p>
                                <p className="hover_Name">{casino?.name}</p>
                              </a>
                              <span className="focus-border">
                                <i></i>
                              </span>
                            </li>
                          );
                        })}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default CasinoGamesSection;
